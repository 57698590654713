#leaderboard {
    position: relative;
    padding:30px 44px;

    .close-button {
        position: absolute;
        right:30px;
        top:30px;
        border-radius: 100%;
        border:none;
        width:40px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2A2F3D;
        z-index: 10;
        cursor: pointer;
    }

    .leaderboard-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 2;

        h1 {
            margin: 0;
            font-size:40px;
            font-weight: 600;
        }
    }

    .currency-selector {
        margin-left:20px;

        button {
            background-color: transparent;
            border:none;
            color:#7D8496;
            font-size:18px;
            padding-top:10px;
            padding-bottom:10px;

            &.active {
                color:white;
            }
            &:first-child {
                border-right:1px solid #2E323D;
                padding-right:20px;
            }
            &:last-child {
                padding-left:20px;
            }
        }
    }

    .leaderboard-ranking {
        padding-top:30px;
        display: flex;
        flex-wrap: nowrap;

        .change-counter {
            height:40px;
            width:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border:none;
            margin-top:-20px;
            background-color: #2A2F3D;

            img {
                height:15px;
            }
        }

        .rank {
            width:33%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .pfp-container {
                > div {
                    position: relative;
                    z-index: 2;

                    &:first-child {
                        border-radius: 100%;
                        width:100px;
                        height:100px;
                        transition:200ms;

                        &:hover {
                            height:105px;
                            width:105px;
                            margin-bottom:-5px;

                            img:not(.light) {
                                height:105px;
                                width:105px;
                            }
                        }

                        img:not(.light) {
                            cursor: pointer;
                            transition:200ms;
                            height:100px;
                            width:100px;
                            border-radius: 100%;
                            position: relative;
                            z-index: 2;
                        }

                        .light {
                            position: absolute;
                            top:-70px;
                            left:-80px;
                            right:0;
                            width:250px;
                            margin:auto;
                            z-index: 1;
                        }
                    }

                    &.bg-light {
                        opacity: 0.40;
                        filter: blur(16px);
                        border-radius: 100%;
                        height: 100px;
                        width: 100px;
                        position: absolute;
                        z-index: 0;
                        top:0px;
                    }

                    &.ranking {
                        display: flex;
                        justify-content: center;
                        margin-top:20px;
                        margin-bottom:20px;
                        
                        img { height:30px; }
                    }
                }
            }

            .user-info {
                white-space: nowrap;

                span {
                    display: block;
                    text-align: center;
                    color:#7D8496;
                    font-size:17px;

                    &.username {
                        color:#FDFDFF;
                        font-weight: 500;
                        font-size:19px;
                    }
                }
            }

            .win-amount {
                margin-top:20px;
                white-space: nowrap;

                > div {
                    display: flex;
                    align-items: center;

                    img {
                        height:24px;
                        margin-right:10px;

                        &.points {
                            height:50px;
                            margin-top:-13px;
                            margin-bottom:-13px;
                            margin-right:0px;
                        }
                    }

                    span {
                        font-size:17px;
                        color:#FDFDFF;
                        
                        .float {
                            color:#7D8496;
                        }
                        
                        &.plus-amount {
                            color:#7D8496;
                            font-size:15px;
                            margin-left:7px;
                        }
                    }
                }
            }

            //Gold
            &.rank-1 {
                .pfp-container > div:first-child {
                    background-color: #EDAB00;
                    border: 3px solid #EDAB00
                }
                .bg-light { background: #edaa00; }
            }
            //Silver
            &.rank-2 {
                .pfp-container > div:first-child {
                    background-color: #8C8C8C;
                    border: 3px solid #8C8C8C
                }
                .bg-light { background: #8C8C8C; }
            }
            //Bronze
            &.rank-3 {
                .pfp-container > div:first-child {
                    background-color: #D7562E;
                    border: 3px solid #D7562E
                }
                .bg-light { background: #D7562E; }
            }
        }
    }

    .leaderboard-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top:2px solid #2E323D;
        margin-top:30px;
        padding-top:20px;

        > div:first-child {
            display: flex;
            align-items: center;

            h3 {
                margin: 0 10px 0 0;
                font-weight: 500;
            }

            span {
                color: #7D8496;
                font-size:14px;
            }
        }
        > div:last-child {
            display: flex;
            align-items: center;

            img {
                height:30px;
                margin-left:10px;

                &.points {
                    height:50px;
                    margin: -10px -15px -10px -2px;
                }
            }

            span {
                font-size:17px;
                color:#FDFDFF;
                
                .float {
                    color:#7D8496;
                }
                
                &.plus-amount {
                    color:#7D8496;
                    font-size:15px;
                    margin-right:12px;
                }
            }
        }
    }

    .close {
        button {
            width: 100%;
            background: #2A2F3D;
            border-radius: 1000px;
            color: #FDFDFF;
            font-size:20px;
            padding:17px;
            border:none;
        }
    }

    @media only screen and (max-width: 786px) {
        padding:18px;

        .close-button {
            top:20px;
            right:20px;
        }

        .currency-selector {
            width:100%;
            display: flex;
            margin:10px 0 0 0;
            
            button {
                width:100%;
                font-size:16px;
            }
        }

        .leaderboard-header {
            h1 {
                font-weight: 500;
                font-size:25px;
            }
        }

        .leaderboard-ranking {
            align-items: center;
            justify-content: space-between;

            .rank {
                .win-amount {
                    .points {
                        margin-right:0px !important;
                        margin-left:-20px;
                    }
                }
            }
        }

        .leaderboard-footer {
            flex-direction: column;
            align-items: flex-start;
            margin-top:30px;
            margin-bottom:30px;
            padding-top:30px;

            > div {
                &:first-child {
                    margin-bottom:10px;
                }
                &:last-child {
                    display: flex;
                    flex-direction: row-reverse;

                    img {
                        margin: 0 10px 0 0;

                        &.points {
                            margin-left:-13px;
                            margin-right:0;
                        }
                    }

                    .plus-amount {
                        margin-left:20px;
                    }
                }
            }
        }
    }
}