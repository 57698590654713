#affiliate {
    span {
        white-space: nowrap;
        color:#FDFDFF;

        .float {
            color:#7D8496;
        }
    }

    h1 {
        font-weight: 600;
        font-size:40px;
        margin-bottom:-10px;
        margin-top:-10px;
    }

    .affiliate-header {
        padding:24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;

            > div {
                display: flex;
                align-items: center;
                margin-left:20px;

                img {
                    height: 30px;
                    margin-left: 10px;
                }
    
                span { font-size: 20px }
            }

            button {
                background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                color: #FDFDFF;
                font-size:19px;
                padding:5px 34px;
                border:none;
                border-radius: 1000px;
            }
        }
    }

    .affiliate-nav {
        margin:0 24px;
        margin-top:-25px;
        border-bottom: 1px solid #2E323D;

        p {
            color:#7D8496;
            font-size:19px;
            max-width: 60%;

            img {
                vertical-align: top;
                margin-left: 5px;
            }
        }

        > div {
            display: flex;
            margin-top:30px;

            > div {
                width:50%;
                position: relative;

                &:first-child {
                    margin-right:10px;
                }
                &:last-child {
                    margin-left:10px;

                    .button-container {
                        height:50%;
                        display: flex;
                        align-items: center;
                        margin-right:-10px;
                        
                        span {
                            margin-right:20px;
                            color:#7D8496;
                            font-size:16px;
                        }

                        button {
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            color: #FBFBFB7A;
                            font-size:19px;
                            padding:8px 34px;
                            border:none;
                            border-radius: 1000px;

                            &:hover {
                                color:white;
                            }
                        }
                    }
                }

                &.read-only {
                    .label {
                        top: 6px;
                        font-size:16px;
                        bottom: 10px;
                        opacity: 1;
                        color:rgba(125, 132, 150, 0.48);
                    }
                    .animated-input {
                        padding-top:27px;
                        padding-bottom:4px;
                    }
                }

                input {
                    width:100%;
                    box-sizing: border-box;
                    font-size:19px;
                    color:white;
                    background-color: #1E222C;
                    border:none;
                    border-radius: 1000px;
                    padding:16px 160px 16px 30px;
                    margin-bottom:23px;
                    outline: none;
                    transition:200ms;
                    height:60px;

                    &.disabled {
                        color:rgba(125, 132, 150, 0.48);
                    }
                    &:focus:not(.disabled) {
                        background-color: rgb(40, 45, 62);

                        &.animated-input {
                            padding-top:27px;
                            padding-bottom:4px;
                        }
                    }
                    &:valid {
                        &.animated-input {
                            padding-top:27px;
                            padding-bottom:4px;
                        }
                    }
                }

                .animated-input:focus ~ .label,
                .animated-input:not(:focus):valid ~ .label {
                    top: 6px;
                    font-size:16px;
                    bottom: 10px;
                    opacity: 1;
                }

                .label {
                    position: absolute;
                    pointer-events: none;
                    left: 30px;
                    top: 16px;
                    color:#7D8496;
                    font-size:20px;
                    transition: 0.2s ease all;
                }

                .button-container {
                    position: absolute;
                    right:20px;
                    top:10px;

                    button {
                        border:none;

                        img { height:25px; }
                        
                        &.copy-button {
                            background-color: transparent;
                        }
                        &.regenerate-button {
                            border-radius: 100%;
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            height:40px;
                            width:40px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            margin-left:10px;
                        }
                    }
                }
            }
        }
    }

    .affiliate-overall {
        padding-bottom:35px;
        margin:15px 24px 0 24px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #2E323D;

        > div {
            width:100%;

            h3 {
                color:#7D8496;
                font-weight: 400;
                margin-bottom:10px;
            }

            p {
                margin:5px 0 0 0;
                font-size:20px;
            }

            > div {
                display: flex;
                align-items: center;
                margin-top:5px;

                img {
                    height: 30px;
                    margin-right:10px;
                }

                span {
                    font-size: 20px;
                }
            }
        }
    }

    .sorting-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0 20px;
        margin-top:30px;
        margin-bottom:30px;

        .sort {
            background: #1E222C;
            border-radius: 1000px;
            padding:17px 30px;
            width:100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            img {
                width:15px;
            }

            span {
                color: #7D8496;
                font-size:20px;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .affiliate-nav {
            > div {
                flex-direction: column;

                > div {
                    width:100%;
                    margin:0 !important;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .affiliate-nav {
            > p { max-width: 100%; }
        }
    }
    @media only screen and (max-width: 564px) {
        .affiliate-overall {
            flex-direction: column;
            padding-bottom:10px;

            > div {
                margin-bottom:20px;
            }
        }
    }
    @media only screen and (max-width: 476px) {
        .affiliate-header {
            flex-direction: column;
            align-items: flex-start;

            > div { margin-top:20px; }
        }
    }
    @media only screen and (max-width: 380px) {
        .affiliate-nav {
            > div {
                > div .button-container button {
                    padding:8px 20px !important;
                }
            }
        }
    }
}

div.affiliate-table {
    overflow-x: hidden;
    
    div.rdt_TableHeadRow {
        height:90px;
    }

    div.rdt_TableCell {
        font-size: 19px;

        div.user-container {
            img {
                height:40px;
                border-radius: 100%;
            }
        }

        div.date {
            color:#7D8496;
            font-size:18px;
        }
    }

    .currency-container {
        display: flex;
        align-items: center;

        span {
            font-size:20px !important;
        }

        img {
            height: 30px;
            margin:0 0 0 10px;

            &.points {
                margin:-15px -20px -15px -5px;
            }
        }
    }

    .mobile-row {
        width: 100%;
        flex-direction: column;
        
        > .mobile-row-data {
            width:100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px;

            &.user-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size:17px;
                padding-left:0px;

                .date {
                    margin-left:50px;
                    margin-top:-15px;
                }
            }

            &.earning-info {
                width:100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left:80px;

                > div {
                    margin:0 10px;
                    flex-direction: column;

                    > .currency-container {
                        flex-direction: row;
                        height:45px;
                        
                        img {
                            margin-left:-10px;
                            height:25px;
                        }
                    }
                }
            }

            > div {
                display: flex;
                align-items: center;
                font-size:17px;
            }

            .gray {
                color:#7D8496 !important;
                font-size:16px !important;
            }

            .pfp {
                height:40px;
                border-radius: 100%;
            }
            
            .username {
                margin-top:-15px;
            }

            .currency-container {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                span {
                    font-size:17px !important;
                }

                img {
                    height: 30px;
                    margin:0 0 0 10px;

                    &.points {
                        margin:-15px -20px -15px -5px;
                    }
                }
            }
        }

        .date {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width:100%;
            font-size:16px !important;
            margin-top:-10px;
        }
    }

    @media only screen and (max-width: 1000px) {
        div.rdt_TableCell { height: fit-content !important; }

        .rdt_TableHead { display: none; }
    }
}