.items-container {
    padding:24px 24px 150px 24px;

    .header {
        margin-top:0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        > div {
            display: flex;
            align-items: center;

            h1 {
                font-weight: 600;
                font-size:40px;
                margin:0 20px -10px 0 !important;
            }

            > div {
                display: flex;
                align-items: center;

                .tag {
                    color:#FDFDFF;
                    padding:6px 26px;
                    background-color: #1E222C;
                    border-radius: 1000px;
                    font-size:18px;
                    transition: 200ms;
                    cursor: pointer;
    
                    &:hover {
                        background-color: #2d323f;
                    }
                }

                img {
                    margin-left:20px;
                    height:22px;
                }
            }
        }

        a {
            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size:18px;

            img {
                vertical-align: middle;
                margin-right:10px;
                height:25px;
            }
        }
    }

    .search-container {
        margin-top:37px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;

            &.search {
                position: relative;
                flex-grow: 1;
                margin-right:15px;

                input {
                    width:100%;
                    padding:0 0px 0 55px;
                    background-color: #1E222C;
                    border:none;
                    border-radius: 1000px;
                    outline:none;
                    color:white;
                    font-size:20px;
                    height:100%;
                }
        
                .icon {
                    position: absolute;
                    height:23px;
                    bottom:20px;
                    left:20px;
                }
            }

            > div {
                height: 60px;

                &.sort {
                    background-color: #1E222C;
                    padding:0 30px;
                    border-radius: 1000px;
                    display: flex;
                    align-items: center;
                    margin-right:15px;
                    white-space: nowrap;
                    cursor: pointer;
    
                    img {
                        margin-left:10px;
                    }
                }
    
                &.reload {
                    background-color: #1E222C;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    aspect-ratio: 1;
                    cursor: pointer;
    
                    img {
                        height:23px;
                    }
                }
            }
        }
    }

    .items-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin:25px 0;

        h2 {
            font-weight: 500;
            color:#FDFDFF;
            font-size:25px;
            margin:0 20px 0 0;
        }
        
        span {
            color:#7D8496;
            font-size:17px;
        }
    }

    .items {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 25px;
    }

    .items-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom:0;
        margin-left:-24px;
        background-color: #1E222C;
        border-top: 1px solid #2E323D;
        height: 110px;
        padding:0 24px;
        box-sizing: border-box;

        .receive-amount {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size:21px;

            .price {
                display: flex;
                align-items: center;
                margin-left:20px;
                font-size:18px;

                span {
                    color:white;
                    
                    .float {
                        color:#7D8496;
                    }
                }

                img {
                    height:60px;
                }
            }
        }

        .buttons {
            button {
                border:none;
                border-radius: 1000px;
                font-size:18px;
                padding:15px 30px;
                background-color: #2A2F3D;
                transition:200ms;
                color:white;

                &:hover {
                    background-color: #363e52;
                }

                &.active {
                    margin-left:10px;
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);

                    &:hover {
                        background: linear-gradient(258.68deg, #3965d6 -25.12%, #1b4cc8 57.73%, #0d2e84 130.84%);
                    }
                }
            }
        }
    }

    .searching {
        width:100%;
        margin-top:200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-weight: 500;
            color:#FDFDFF;
            font-size:26px;
            margin-bottom: 0;
        }

        .warning {
            color:#7D8496;
            font-size:18px;
            margin:0;
        }

        .counter {
            font-size:50px;
            font-weight: 600;
            margin-top:30px;
        }

        button {
            background: #2A2F3D;
            border-radius: 1000px;
            padding:20px 36px;
            border: none;
            color:white;
            font-size:18px;
            margin-top:-30px;
        }
    }

    @media only screen and (max-width: 1143px) {
        .items {
            grid-template-columns: auto auto auto;
        }
    }

    @media only screen and (max-width: 961px) {
        .header {
            > div {
                flex-direction: column;
                align-items: flex-start;

                > div { margin-top:20px; }
                span { font-size: 16px !important; }
                h1 { font-size: 35px !important; }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .items {
            grid-template-columns: auto auto;
        }
    }

    @media only screen and (max-width: 836px) {
        .items-footer {
            .buttons button {
                padding: 10px 13px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding:15px 15px 180px 15px;

        .searching {
            margin-top:0;
            height:calc(100vh - 300px);
        }

        .items-header {
            h2 {
                font-size:22px;
                margin-right:10px;
            }
            span {
                font-size:14px;
            }
        }

        .items-footer {
            bottom:80px;
            width:100% !important;
            left:25px;

            .buttons button {
                padding: 10px 13px;
            }
        }
    }

    @media only screen and (max-width: 673px) {
        .header {
            a {
                margin-top:20px;
                font-size:15px;

                img { height:20px; }
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .search-container {
            flex-direction: column;
            align-items: flex-start;

            > .search {
                margin-bottom:15px;
                width:100%;
            }

            > div:not(.search) {
                width:100%;
                justify-content: flex-start;

                .sort {
                    width:100%;
                }
            }
        }

        .items {
            gap: 15px;
        }

        .items-footer {
            flex-direction: column;
            align-items: flex-start;
            height:fit-content;
            padding:0 15px 10px 15px;

            .receive-amount {
                margin:20px 0;
                font-size:5vw;

                .price {
                    white-space: nowrap;
                    font-size:16px;

                    img {
                        height:60px;
                        margin-left:-5px;
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                
                button {
                    margin:0 0 20px 0 !important;
                    width: 100%;
                    padding:15px 30px;
                }
            }
        }
    }
}