#mines {
    .controllers {
        padding:12px 0;
        display: flex;
        flex-wrap: nowrap;
        margin-right:-5px;

        button {
            padding:8px 30px;
            font-size:19px;
            border:none;
            border-radius: 1000px;
            background-color: #2A2F3D;
            color:#FDFDFF;
            margin:0 5px;
            transition:200ms;

            &:hover {
                background-color: #3B4256;
            }

            &:last-child {
                margin-right:-5px;
            }
        }
    }

    .content {
        display: flex;
        padding:24px;
        padding-bottom:30px;

        .bet-placement {
            width:100%;
            padding-right:30px;

            &.disabled {
                input {
                    color:#7D84967A;
                    
                    &::placeholder {
                        color:#7D84967A;
                    }
                }

                > div {
                    .label {
                        top: 4px;
                        font-size:16px;
                        bottom: 10px;
                        opacity: 1;
                        color:#7D84967A;
                    }
                    .animated-input {
                        padding-top:27px;
                        padding-bottom:4px;
                        color:#7D84967A;

                        &:focus {
                            background-color: #1E222C;
                        }
                    }
                }
                
                .button-container, .controllers {
                    button {
                        background-color:#2A2F3D !important;
                        color:#7D84967A;
                        cursor: default;
                    }
                }
            }

            .amount-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #1E222C;
                padding:0 24px 0 0;
                height:65px;
                margin-bottom:20px;
    
                .currency-selection {
                    display: flex;
                    align-items: center;
                    padding-right:10px;
                    border-right: 1px solid #2E323D;
                    height:100%;
                    cursor: pointer;

                    #currency-button {
                        width:90px;
                        padding-left:24px;
                    }
    
                    img.down {
                        height:8px;
                        margin-left:15px;
                        transition: 100ms;
                    }
                    img.robux {
                        height:30px;
                    }
                    img.points {
                        height:60px;
                        margin-left:-15px;
                        margin-right:-15px;
                    }
                }
    
                .enter-bet-amount {
                    padding:12px 0 12px 20px;
                    flex-grow: 1;
    
                    input {
                        width: 100%;
                        outline: none;
                        border: none;
                        background-color: transparent;
                        font-size:20px;
                        color:white;
                        -moz-appearance: textfield;
                    }
                }
            }

            .mine-count {
                position: relative;

                input {
                    width:100%;
                    box-sizing: border-box;
                    font-size:18px;
                    color:white;
                    background-color: #1E222C;
                    border:none;
                    border-radius: 1000px;
                    padding:16px 160px 16px 30px;
                    margin-bottom:23px;
                    outline: none;
                    transition:200ms;
                    height:60px;

                    &:focus {
                        background-color: rgb(40, 45, 62);

                        &.animated-input {
                            padding-top:27px;
                            padding-bottom:4px;
                        }
                    }
                    &:valid {
                        &.animated-input {
                            padding-top:27px;
                            padding-bottom:4px;
                        }
                    }
                }

                .animated-input:focus ~ .label,
                .animated-input:not(:focus):valid ~ .label {
                    top: 4px;
                    font-size:16px;
                    bottom: 10px;
                    opacity: 1;
                }

                .label {
                    position: absolute;
                    pointer-events: none;
                    left: 30px;
                    top: 16px;
                    color:#7D8496;
                    font-size:20px;
                    transition: 0.2s ease all;
                }

                .button-container {
                    position: absolute;
                    right:15px;
                    top:10px;
                    display: flex;

                    button {
                        border:none;
                        background-color: #2A2F3D;
                        width:40px;
                        height:40px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 200ms;

                        &:last-child {
                            margin-left:10px;
                        }

                        &:hover {
                            background-color: #3B4256;
                        }
                    }
                }
            }

            .buttons {
                height:65px;
                display: flex;

                .info-box {
                    aspect-ratio: 1;
                    border-radius: 100%;
                    height:100%;
                    background-color: #1E222C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition:200ms;
                    cursor: pointer;

                    &:hover, &.active {
                        background-color: #3B4256;
                    }

                    img {
                        height:25px;
                        width:25px;
                    }
                }

                .button-box {
                    width:100%;

                    &.waiting button {
                        color:#FBFBFB7A;
                        box-shadow: none;
                        cursor: default;

                        &:hover {
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        }
                    }
                    
                    button {
                        white-space: nowrap;
                        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                        border-radius: 1000px;
                        border:none;
                        color:#FDFDFF;
                        font-size:20px;
                        height:100%;
                        padding:0 30px;
                        margin-left:10px;
                        width:calc(100% - 10px);

                        &:hover {
                            background: linear-gradient(258.68deg, #3360d0 -25.12%, #1749c8 57.73%, #0a2c80 130.84%);
                        }
                    }
                }
            }

            .total-profit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #1E222C;
                height: 65px;
                border-radius: 1000px;
                padding:0 20px;
                margin-top:20px;

                .info {
                    display: flex;
                    align-items: center;

                    > div {
                        display: flex;

                        img {
                            height:30px;
                        }

                        &:first-child { align-items: center; }

                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            padding-left:15px;

                            span {
                                font-size:17px;
                                color:#7D8496;
                            }

                            p {
                                font-size: 20px;
                                color:#FDFDFF;
                                margin: 0;
                                margin-top:-5px;
                            }
                        }
                    }
                }

                .multiplier {
                    color:#7D8496;
                    font-size:17px;

                    &.lose {
                        color:#C11B1B;
                    }
                    &.win {
                        color:#039700;
                    }
                }
            }
        }
    
        .game {
            display:grid;
            grid-template-columns: repeat(5, 100px);
            gap:10px;

            .mine {
                height:100px;
                width:100px;
                border-radius: 16px;
                background-color: #1E222C;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 200ms;
                transition-timing-function: ease-in-out;
                position: relative;

                img:not(.light) {
                    height:40px;
                    height:40px;
                }

                &:hover:not(.game-started) {
                    background-color: #20242f;
                }

                &.game-started {
                    background-color: #2A2F3D;
                    cursor: pointer;

                    img {
                        opacity: 0.3;
                        transition: 200ms;
                    }

                    &:hover {
                        background-color: #3B4256;

                        img { opacity: 1; }
                        .multiplier { opacity: 1; }
                    }

                    &.revealed {
                        opacity: 1;
                        cursor: default;

                        img { opacity: 1; }
                    }
                    &.not-revealed {
                        opacity: 0.45;
                        cursor: default;
                    }
                }

                &.no-mine {
                    animation-duration: 230ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-name: revealMinePartThree;

                    img:not(.light) {
                        height:60px;
                        position: relative;
                        z-index: 2;
                        animation-duration: 300ms;
                        animation-iteration-count: 1;
                        animation-timing-function: ease-in-out;
                        animation-name: revealMinePartTwo;
                    }
                }
                &.mine-found {
                    animation-duration: 230ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-name: revealMinePartThree;

                    img:not(.light) {
                        height:120px;
                        position: relative;
                        z-index: 2;
                        animation-duration: 300ms;
                        animation-iteration-count: 1;
                        animation-timing-function: ease-in-out;
                        animation-name: revealMinePartTwo;
                    }

                    .light {
                        height:100%;
                        width:100%;
                        bottom:0px;
                    }
                }

                &.clicked-mine-anim {
                    animation-duration: 400ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-name: revealMinePartOne;
                }

                .light {
                    position: absolute;
                    height:100%;
                    width:100%;
                    bottom:0px;
                }

                .multiplier {
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    border-radius: 1000px;
                    color:white;
                    padding:11px 20px;
                    position: absolute;
                    opacity: 0;
                    transition: 200ms;
                }
            }
        }
    }

    .bets {
        padding-top:10px;

        .bets-header {
            padding:0 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;

                h2 {
                    font-weight: 500;
                    margin-right:10px;
                }

                span {
                    color:#7D8496;
                    font-size:18px;
                }
            }

            .currency-filter {
                display: flex;
                align-items: center;

                > div {
                    display: flex;
                    align-items: center;
                    padding:3px 7px;
                    cursor: pointer;

                    &.inactive {
                        span, .float {
                            color:#7D8496;
                        }
                    }

                    &:first-child {
                        padding-right:25px;
                        border-right: 2px solid #2e323d;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }

                    img {
                        height:30px;
                        margin-right:10px;

                        &.points {
                            height:55px;
                            margin-right:0;
                            margin-left:-5px;
                        }
                    }

                    span {
                        white-space: nowrap;
                        color:#FDFDFF;
                        font-size:19px;
            
                        .float {
                            color:#7D8496;
                        }
                    }
                }
            }
        }

        .bets-body {
            .bet {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height:80px;
                padding:0 40px 0 24px;
                
                &:nth-child(odd) {
                    background-color: #1E222C;
                }

                > div {
                    display: flex;
                    align-items: center;

                    &:first-child {
                        width:40%;
                    }

                    &.mines {
                        width:20%;
                        justify-content: center;
                        align-items: center;
                        color: #7D8496;
                        font-size: 18px;

                        img { margin-right: 6px; }
                    }

                    &.multiplier {
                        width:20%;
                        justify-content: center;

                        > div {
                            border-radius: 1000px;
                            padding: 9px 22px;
                            font-size: 15px;
                        }

                        .win {
                            color:#039700;
                            background: rgba(3, 151, 0, 0.1);
                            
                        }
                        .lose {
                            color:#C11B1B;
                            background: rgba(193, 27, 27, 0.1);
                        }
                    }

                    &:last-child {
                        width:20%;
                        justify-content: flex-end;
                        align-items: center;

                        img {
                            margin-left:10px;
                            height:30px;
                            width:30px;

                            &.points {
                                height:60px;
                                width:60px;
                                margin-right:-15px;
                                margin-left:-5px;
                            }
                        }
                        span {
                            font-size:19px;
                            color:#FDFDFF;
                            
                            .float {
                                color:#7D8496;
                            }
                        }
                        .addup-value {
                            font-size:16px;
                            color: #7D8496;
                            margin-right:10px;
                        }
                    }
                }

                .pfp {
                    height:35px;
                    width:35px;
                    border-radius: 100%;
                    margin-right:20px;
                }

                .username {
                    font-size:19px;
                }

                .me {
                    font-size:20px;
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    border-radius: 1000px;
                    padding: 2px 12px;
                    margin-left:10px;
                }
            }

            //Bet row for mobile resolution
            .mobile-bet {
                padding:20px 15px;
                height:150px;

                &:nth-child(odd) {
                    background-color: #1E222C;
                }

                .user-info {
                    display: flex;
                    padding-bottom:20px;

                    > div {
                        display: flex;

                        &:last-child {
                            flex-direction: column;
                            margin-left:10px;

                            .amount {
                                margin-top:10px;
                                display: flex;
                                flex-direction: row-reverse;
                                align-items: center;
                                justify-content: flex-end;

                                img {
                                    margin-right:10px;
                                    height:30px;
                                    width:30px;
        
                                    &.points {
                                        height:50px;
                                        width:50px;
                                        margin-left:-15px;
                                        margin-right:-5px;
                                        margin-bottom:-20px;
                                        margin-top:-20px;
                                    }
                                }
                                span {
                                    font-size:19px;
                                    color:#FDFDFF;
                                    
                                    .float {
                                        color:#7D8496;
                                    }
                                }
                                .addup-value {
                                    font-size:15px;
                                    color: #7D8496;
                                    margin-left:10px;
                                }
                            }
                        }

                        .pfp {
                            height:40px;
                            width:40px;
                            border-radius: 100%;
                        }

                        .username {
                            font-size:17px;
                        }
        
                        .me {
                            font-size:16px;
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            border-radius: 1000px;
                            padding: 2px 12px;
                            margin-left:10px;
                        }
                    }
                }

                .bet-info {
                    width:100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px 0 50px;
                    box-sizing: border-box;

                    > div {
                        display: flex;
                        align-items: center;

                        &.mines {
                            justify-content: center;
                            align-items: center;
                            color: #7D8496;
                            font-size: 18px;
    
                            img { margin-right: 6px; }
                        }
    
                        &.multiplier {
                            justify-content: center;
    
                            > div {
                                border-radius: 1000px;
                                padding: 9px 22px;
                                font-size: 15px;
                            }
    
                            .win {
                                color:#039700;
                                background: rgba(3, 151, 0, 0.1);
                                
                            }
                            .lose {
                                color:#C11B1B;
                                background: rgba(193, 27, 27, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1168px) {
        .content {
            flex-direction: column;
            align-items: center;

            .bet-placement {
                padding: 0;

                .buttons .button-box button { box-shadow: none; }
            }

            .game {
                margin-top:50px;
                width: 100%;
                grid-template-columns: repeat(5, calc(20% - 8px));

                .mine {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;

                    > img:not(.light) {
                        height:40%;
                    }

                    &.mine-found {
                        img:not(.light) {
                            height:80px;
                        }
                    }
                    &.no-mine {
                        img:not(.light) {
                            height:50%;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 930px) {
        .bets {
            .bets-header {
                flex-direction: column;
                align-items: flex-start;
        
                .currency-filter {
                    height:50px;
                    margin-top:-10px;
                    margin-bottom:30px;
        
                    > div { padding:0; }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-bottom:140px;

        .content {
            padding:34px 15px;

            .bet-placement {
                .amount-container {
                    height:60px;

                    .currency-selection {
                        height:100%;
                    }
                    .controllers {
                        display: none;
                    }
                }
            }

            .game {
                margin-top:30px;
            }
        }
    }

    @media only screen and (max-width: 666px) {
        .content {
            .game {
                gap:2vw;
                grid-template-columns: repeat(5, calc(20% - 1vw - 3px));

                .mine {
                    &.mine-found {
                        img:not(.light) {
                            height:50px;
                        }
                    }
                    &.no-mine {
                        img:not(.light) {
                            height:40%;
                        }
                    }
                }
            }
        }
    }
}

#currency-menu {
    margin-top:10px;
    margin-left:0px;
    border-radius: 0;

    .currency-menu-item {
        border:1px solid #2E323D;
        border-top:none;
        border-left:0;
        background-color: #2A2F3D;
        height:65px;
        width:101px;
        margin-top:-2px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 16px 16px;
        transition:100ms;
        overflow: hidden;

        &:hover {
            background-color: #3B4256;
        }

        img {
            padding: 5px;
            height:40px;

            &.points {
                height:70px;
                margin:0 -15px 0 -10px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        margin-left:-1px;
    }
}

@keyframes revealMinePartOne {
    0% {
        transform: scale(1.0);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1.0);
    }
    100% {
        transform: rotateZ(0);
    }
}

@keyframes revealMinePartTwo {
    0% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes revealMinePartThree {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}