#profile {
    padding-bottom:20px;

    h2 {
        font-weight: 500;
        font-size: 26px;
        margin-right:10px;
    }

    .currency-container {
        span {
            white-space: nowrap;
            color:#FDFDFF;
            font-size:20px;

            .float {
                color:#7D8496;
            }
        }
    }

    //Top part of profile page
    .profile-header {
        padding:24px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom:20px;
        border-bottom:2px solid #2E323D;

        .profile-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .pfp-container {
                position: relative;
                margin-right:30px;

                .pfp {
                    width: 120px;
                    height: 120px;
                    border-radius: 100%;
                    border: 3px solid #D7562E;
                }

                .secondary-pfp {
                    position: absolute;
                    left:0;
                    right:0;
                    margin: auto;
                    bottom:-10px;
                    width:40px;
                    height:40px;
                    border: 3px solid #D7562E;
                    border-radius: 100%;
                    background-color: #171B22;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height:70%;
                    }
                }
            }

            .general-info {
                .id { color: #7D8496; }
                .username {
                    color:#FDFDFF;
                    font-weight:500;
                    font-size:23px;
                    margin-bottom:4px;
                }
                .discord {
                    display: flex;
                    align-items: center;
                    color:#7D8496;
                    font-size:17px;

                    &.inactive {
                        cursor:pointer;
                        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    img {
                        margin-right:10px;
                        height:16px;
                    }
                }
            }
        }

        .deposit-withdraw-buttons {
            display: flex;
            align-items: flex-end;
            height:100%;
            margin-bottom:15px;

            a {
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding:17px 23px;
                    color:#FDFDFF;
                    font-size:20px;
                    border-radius: 1000px;
                    margin:0 8px;
                    transition:100ms;

                    &.deposit-button {
                        background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);

                        &:hover {
                            background: linear-gradient(215.71deg, #537eec -7.35%, #1848c3 57.6%, #2f5ed5 110.3%), linear-gradient(258.68deg, #3e69d8 -25.12%, #2051cf 57.73%, #0e3189 130.84%);
                        }
                    }
                    &.withdraw-button {
                        background-color: #2A2F3D;

                        &:hover {
                            background-color: #212631;
                        }
                    }

                    img {
                        margin-right:15px;
                    }
                }
            }
        }
    }

    //Part under the profile header
    .profile-navbar {
        padding:24px;
        padding-top:10px;
        padding-bottom:20px;
        border-bottom:2px solid #2E323D;

        .statistic {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom:-20px;

            button {
                background-color: transparent;
                border:none;
                color:#7D8496;
                font-size:19px;
                padding: 7px 20px;
                cursor: pointer;

                &.left-button {
                    border-right:2px solid #2E323D !important;
                }

                &.active {
                    color:#FDFDFF;
                }
            }
        }

        .datas {
            display: flex;
            justify-content: space-between;

            > div {
                flex: 1 1 0px;

                h3 {
                    color:#7D8496;
                    font-weight: 400;
                    margin-bottom: 5px;
                    font-size: 18px;
                }

                > div {
                    display: flex;
                    align-items: center;

                    img {
                        height:34px;
                        margin-right:10px;

                        &.points {
                            height:41px;
                            margin:0 0 -7px -10px;
                        }
                    }
                }
            }
        }
    }

    //History
    .profile-body {
        .history-header {
            padding:0 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                padding-bottom:20px;
            }

            button {
                background-color: transparent;
                color:#7D8496;
                font-weight: 500;
                border:none;
                font-size:20px;
                padding-bottom:20px;
                border-bottom: 2px solid #2E323D;
                cursor: pointer;

                &:last-child:not(.active) {
                    padding-left:30px;
                }
                &:first-child:not(.active) {
                    padding-right:30px;
                }

                &.active {
                    color:#FDFDFF;
                    border-bottom: 2px solid #1F52D6;
                }
            }
        }

        .sorting-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0 20px;
            margin-bottom:30px;

            .sort {
                background: #1E222C;
                border-radius: 1000px;
                padding:17px 30px;
                width:100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                img {
                    width:15px;
                }

                span {
                    color: #7D8496;
                    font-size:20px;
                }
            }
        }
    }

    @media only screen and (max-width: 1088px) {
        .profile-header {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .profile-info {
                flex-direction: column;
                align-items: center;

                .pfp-container { margin:10px 0 30px 0; }

                .general-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .what {
                        margin:0;
                    }

                    .discord {
                        margin-top:10px;
                    }
                }
            }

            .deposit-withdraw-buttons {
                margin-top:20px;

                .deposit-button {
                    box-shadow: none !important;
                }
            }
        }
    }

    @media only screen and (max-width: 975px) {
        .profile-navbar {
            .datas {
                flex-direction: column;

                > div {
                    margin-bottom:10px;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .profile-header {
            .deposit-withdraw-buttons {
                margin-top:0px;
                flex-direction: column;
                width:100%;

                > a {
                    width:100%;
                    margin-top:15px;

                    .deposit-button {
                        box-shadow: 0px 24px 48px rgb(31 82 214 / 16%) !important;
                    }
                }
            }
        }

        .profile-navbar {
            .statistic {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom:15px;

                h2 {
                    margin-bottom:10px;
                }

                button {
                    &:first-child {
                        padding-left:0;
                    }
                }
            }
        }

        .profile-body {
            .history-header {
                flex-direction: column;
                align-items: flex-start;
                padding-top:20px;

                h2 {
                    margin:0;
                    font-size:24px;
                }

                > div {
                    display: flex;
                    width: 100%;
                    margin-bottom:30px;

                    button {
                        width: 100%;
                    }   
                }
            }
        }
    }
}

//Customized Data Table
div.profile-table {
    div.rdt_TableCell {
        //Game History
        font-size: 19px;
        
        div.currency-container {
            img {
                height:30px;

                &.points {
                    height:60px;
                    margin-left:-15px;
                    margin-right:-5px;
                }
            }
        }

        div.ratio-container {
            padding:10px 23px;
            border-radius: 1000px;
            font-size:16px;

            &.win {
                background: rgba(3, 151, 0, 0.1);
                color:#039700;
            }
            &.lose {
                background: rgba(193, 27, 27, 0.1);
                color:#C11B1B;
            }
        }

        div.game-container {
            img { height: 22px; }
        }

        //Transaction history
        div.method-container {
            font-size:17px;

            span {
                &.id {
                    display: none;
                    color: #7D8496;
                    opacity: 0;
                    transition: 600ms;
                }
            }

            &.contains-id {
                &:hover {
                    span {
                        display: none;
                    }
                    span.id {
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }

        div.queue-container {
            font-size:17px;

            span {
                background-color: #2a2f3d;
                border-radius: 100%;
                width:40px;
                height:40px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
            }
        }

        div.type {
            > span {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    margin-right: 16px !important;
                }
            }
        }

        div.date {
            color:#7D8496;
            font-size:18px;
        }

        div.transaction-currency span {
            font-size:19px !important;
        }

        div.status {
            padding:10px 23px;
            border-radius: 1000px;
            font-size:16px;
            white-space: nowrap;

            &.success {
                color:#039700;
                background: rgba(3, 151, 0, 0.1);
            }
            &.pending {
                color:#FFA800;
                background: rgba(255, 168, 0, 0.1);
            }
            &.refused, &.failed {
                color:#C11B1B;
                background: rgba(193, 27, 27, 0.1);
            }
        }
    }

    .mobile-row {
        width: 100%;
        flex-direction: column;
        
        > .mobile-row-data {
            width:100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px;

            > div {
                display: flex;
                align-items: center;
                font-size:17px;
            }

            &:not(:first-child) {
                border-top: 1px solid #2E323D;
            }

            .gray {
                color:#7D8496;
                font-size:16px;
            }

            .game-icon {
                height:25px;
            }

            .type-icon {
                width:20px;
            }

            .ratio-container, .status {
                padding:3px 20px;
            }

            .currency-container, .method-container {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                span {
                    font-size:17px !important;
                }

                img {
                    height: 30px;
                    margin:0 0 0 10px;

                    &.points {
                        margin:-15px -20px -15px -5px;
                    }
                }
            }
        }

        .date {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width:100%;
            font-size:16px !important;
            margin-top:-10px;
        }
    }

    @media only screen and (max-width: 966px) {
        div.rdt_TableCell div.date {
            font-size:15px;
        }
        div.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell > .transaction-currency.currency-container > span {
            font-size:14px !important;
            
            > .float { font-size:14px !important; }
        }
    }

    @media only screen and (max-width: 768px) {
        div.rdt_TableCell { height: fit-content !important; }

        div.rdt_TableRow { margin:18px 0; }

        .rdt_TableHead { display: none; }
    }
}