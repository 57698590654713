#crash {
    position: relative;
    min-height: 100vh;

    .crash-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background: url("../../assets/images/crash-background.gif") #13161D;
        //background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: max(100vw, calc(100vh + 220px)) max(100vw, calc(100vh + 220px));
        background-position: left top;
        z-index: -4;
    }

    .banner {
        //overflow: hidden;
        background: #2B28DA;
        background-image: url("../../assets/images/banner-bg.png");
        background-size: cover;
        height:280px;
        width:100%;
        position: relative;
        z-index: 5;

        h1 {
            font-weight: 700;
            margin:0;
            padding:30px;
            font-size:42px;
            position: relative;
            top:-20px;
        }

        .counter {
            position: absolute;
            left:20px;
            bottom:20px;
            display: flex;

            > div {
                border-radius: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(251, 251, 251, 0.16);
                height:80px;
                margin-right:10px;
                aspect-ratio: 1;

                span {
                    font-size:17px;
                    color:rgba(255,255,255,0.6);
                    display: block;

                    &.first {
                        color:white;
                        font-size:20px;
                        margin-bottom:-5px;
                    }
                }
            }
        }

        .gift-images {
            position: absolute;
            top:-70px;
            z-index: 10;
            left:0;
            right:0;
            margin:auto;
            height:460px;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-name: upToDown;
        }

        .close-banner {
            position: absolute;
            top:20px;
            right:20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding:16px;
            border-radius: 100%;
            background: rgba(251, 251, 251, 0.16);
            border:none;
            z-index: 11;

            img {
                height:15px;
            }
        }
        
        .more-info {
            position: absolute;
            right:20px;
            bottom:20px;
            display: flex;
            align-items: center;

            > * {
                &:first-child {
                    margin-right:20px;
                    
                    span {
                        display: block;
                        font-size:17px;
                        color:rgba(255,255,255,0.6);

                        &.first {
                            color:white;
                            font-size:19px;
                        }
                    }
                }
                &:last-child {
                    height:70px;
                    width:70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    background: rgba(251, 251, 251, 0.16);

                    img {
                        height:15px;
                    }
                }
            }
        }
        
        //Right Nav Exists
        @media only screen and (max-width: 1673px) {
            &:not(.no-nav) .gift-images {
                left:15%;
            }
        }
        @media only screen and (max-width: 1400px) {
            &:not(.no-nav) {
                .more-info {
                    display: none;
                }

                .gift-images {
                    right:-500px;
                    height:320px;
                    top:40px;
                }
            }
        }

        //Right Nav doesn't Exist
        @media only screen and (max-width: 1179px) {
            &.no-nav .gift-images {
                left:15%;
            }
        }
        @media only screen and (max-width: 990px) {
            &.no-nav {
                .more-info {
                    display: none;
                }

                .gift-images {
                    right:-600px;
                    height:320px;
                    top:40px;
                }
            }
        }
        @media only screen and (max-width: 544px) {
            &.no-nav {
                .gift-images {
                    right:-470px;
                    height:320px;
                }

                h1 {
                    font-size: 37px;
                }
            }
        }
    }

    .bubbles {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

    .controllers {
        padding:12px 0;
        display: flex;
        flex-wrap: nowrap;

        button {
            padding:8px 30px;
            font-size:19px;
            border:none;
            border-radius: 1000px;
            background-color: #2A2F3D;
            color:#FDFDFF;
            margin:0 5px;
            transition:200ms;

            &:hover {
                background-color: #3B4256;
            }

            &:last-child {
                margin-right:-5px;
            }
        }
    }

    .past-games {
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom:20px;
        padding:24px 24px 0 24px;

        > div {
            border-radius: 1000px;
            padding:15px 45px;
            font-size:17px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            margin:0 5px;
            transition: 100ms;
            position: relative;
            z-index: 2;

            &.win {
                background-color: #1B2B1D;
                color:#039700;

                &:hover {
                    background-color: #1b361f;
                }
            }
            &.not-win {
                background-color: #1E222C;
                color:#7D8496;

                &:hover {
                    background-color: #29324a;
                }
            }
        }
    }

    .game {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0 24px;
        height:480px;
        position: relative;
        clear: both;
        overflow: hidden;

        .game-screen {
            border:1px solid #1E222C;
            border-bottom:0;
            width:100%;
            height:calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            clear:both;
            overflow: hidden;

            > div[class^="section"] {
                display: flex;
                align-items: flex-end;
                flex-grow: 1;
                border-bottom: 1px solid #1E222C;
                width: 100%;
                height:120px;
                color:#7D8496;
                font-size: 17px;

                &:last-child { // Seconds
                    justify-content: space-between;
                    clear:both;
                    overflow: hidden;
                    position: relative;
                    
                    .second {
                        margin-left:13%;
                        width:100%;
                        text-align: right;
                    }
                }
            }

            .multiplier {
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                background-color: #1E222C;
                padding:9px 13px;
                border-radius: 0px 1000px 1000px 0px;
                height:45px;
                bottom:-20px;
                color:#7D8496;
                font-size: 17px;
                min-width: 50px;
                text-align: center;

                &.active {
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    color:white;
                }
            }
        }

        #chart-container {
            position: absolute;
            width:calc(100% - 180px);
            height:calc(100% - 280px);
            left:0px;
            bottom:130px;
            display: flex;
            align-items: flex-end;

            .xAxis {
                font-size: 16px;
                font-family: "Poppins";
            }
            
            .yAxis {
                display: none;
            }

            /*.bubble-animation {
                position: absolute;
                width: 100%;
                top: -200px;
                left: 100px;
            }*/

            //Boom image
            .game-over {
                animation-duration: 3s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;
                animation-name: boom;
                width: 300px;
                opacity: 0;
            }
        }

        .game-started, .game-over {
            position: absolute;
            z-index: 2;
            width:100%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                margin-top:-240px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;

                h2 {
                    text-align: start;
                    color:#FDFDFF;
                    font-weight: 600;
                    font-size:60px;
                    margin:0px 0 0 0;
                    position: relative;
                    z-index: 10;
                }

                p {
                    color:#7D8496;
                    font-family: 600;
                    font-size:23px;
                    margin:0 0 -3px 0;
                }
            }

            .light {
                position: absolute;
                height:200px;
                margin-top:50px;
                top:-160px;
            }
        }

        .game-over {
            h2 {
                color:#C11B1B !important;
                font-size:50px !important;
            }

            img:not(.light) {
                height:300px;
                margin-top:-20px;
            }

            > div {
                margin-top:0px;
            }
        }
        
        .next-round-container {
            position: absolute;
            width:100%;
            height:100%;

            .next-round {
                display: flex;
                align-items: center;
                justify-content: center;
                width:100%;
                position: relative;
                height: 100%;
    
                .counter {
                    height:100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
    
                    .submarine {
                        height:280px;
                        position: absolute;
                        left:0;
                        right:0;
                        margin:auto;
                        top:50%;
                        transform: translateY(-50%);
                        margin-top:-60px;
                        z-index: 2;
                        position: absolute;
                        animation-duration: 1s;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: 1;
                        animation-name: bringSubmarine;

                        &.send-submarine {
                            animation-name: sendSubmarine;
                            top: 200%;
                        }
                    }
    
                    p {
                        position: relative;
                        z-index: 2;
                        font-weight: 500;
                        font-size:25px;
                        color:#FDFDFF;
                        top:-60px;
                    }
                }
    
                .left-bubbles {
                    top: 0;
                    left: 0;
                    position: absolute;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                    animation-name: bringBubbles;

                    &.send-bubbles {
                        animation-name: sendBubbles;
                        top: -800px;
                    }

                    img {
                        position: absolute;
                        animation-duration: 2s;
                        animation-timing-function: ease-in-out;
                        animation-iteration-count: infinite;
    
                        //Bubbles
                        &:nth-child(1) {
                            left:150px;
                            top:300px;
                            animation-duration: 3.5s;
                            animation-name: upToDown;
                        }
                        &:nth-child(2) {
                            left:10px;
                            top:90px;
                            width:130px;
                            animation-name: downToUp;
                        }
                        &:nth-child(3) {
                            left:250px;
                            width:25px;
                            top: 70px;
                            animation-duration: 3s;
                            animation-name: upToDown;
                        }
                        &:nth-child(4) {
                            left:270px;
                            top:180px;
                            width:40px;
                            animation-name: downToUp;
                        }
                    }
                }
    
                .right-bubbles {
                    top: 0;
                    right: 0;
                    position: absolute;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                    animation-name: bringBubbles;

                    &.send-bubbles {
                        animation-name: sendBubbles;
                        top: -800px;
                    }
                    
                    img {
                        position: absolute;
                        animation-duration: 2.2s;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
    
                        //Bubbles
                        &:nth-child(1) {
                            right:240px;
                            top:-20px;
                            width:160px;
                            animation-name: downToUp;
                        }
                        &:nth-child(2) {
                            right:10px;
                            top:90px;
                            width:130px;
                            animation-duration: 2.3s;
                            animation-name: upToDown;
                        }
                        &:nth-child(3) {
                            right:200px;
                            top:220px;
                            animation-duration: 2s;
                            animation-name: downToUp;
                        }
                        &:nth-child(4) {
                            right:290px;
                            top:280px;
                            width:120px;
                            animation-name: upToDown;
                        }
                    }
                }
            }
        }
    }

    .place-bet {
        box-sizing: border-box;
        width:100%;
        position: relative;
        z-index: 2;
        padding:0 24px;
        padding-bottom:30px;
        align-items: center;

        &.chat-visible {
            display: flex;

            .amount-container {
                margin-bottom:0;
                margin-right:10px;
            }
        }

        &.small {
            margin-bottom:80px;

            .amount-container {
                .controllers {
                    display: none;
                }
            }

            .auto-cashout-container {
                flex-direction: column;

                .auto-cashout {
                    width: calc(100% - 48px);

                    > div:first-child {
                        span {
                            margin:0;
                        }
                    }

                    .controllers {
                        margin-right:-15px;

                        button:last-child {
                            display: none;
                        }
                    }
                }

                .buttons {
                    margin-top:20px;
                    width:100%;

                    > div {
                        height:65px;

                        .info-box {
                            margin: 0;
                        }

                        &.button-box {
                            width: calc(100% - 75px);
                        }
                        
                        button {
                            width:100%;
                            padding:0;
                        }
                    }
                }
            }
        }

        .amount-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #1E222C;
            padding:0 24px 0 0;
            height:65px;
            margin-bottom:20px;

            .currency-selection {
                display: flex;
                align-items: center;
                padding-right:10px;
                border-right: 1px solid #2E323D;
                height:100%;
                cursor: pointer;

                #currency-button {
                    width:90px;
                    padding-left:24px;
                }

                img.down {
                    height:8px;
                    margin-left:15px;
                    transition: 100ms;
                }
                img.robux {
                    height:30px;
                }
                img.points {
                    height:60px;
                    margin-left:-15px;
                    margin-right:-15px;
                }
            }

            .enter-bet-amount {
                padding:12px 0 12px 20px;
                flex-grow: 1;

                input {
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size:20px;
                    color:white;
                    -moz-appearance: textfield;
                }
            }
        }

        .auto-cashout-container {
            display: flex;
            align-items: center;
            height:65px;

            .auto-cashout {
                background-color: #1E222C;
                border-radius: 1000px;
                position: relative;
                flex-grow: 1;
                height:100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding:0 24px;

                span {
                    color:#7D8496;
                    white-space: nowrap;
                    margin-right:100px;
                }

                input {
                    margin-top:-10px;
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size:18px;
                    color:white;
                    -moz-appearance: textfield;
                }

                > div {
                    &:first-child {
                        width:100%;
                    }
                }
            }

            .buttons {
                height:65px;
                display: flex;

                .info-box {
                    aspect-ratio: 1;
                    border-radius: 100%;
                    height:100%;
                    margin-left:10px;
                    background-color: #1E222C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition:200ms;
                    cursor: pointer;

                    &:hover, &.active {
                        background-color: #3B4256;
                    }

                    img {
                        height:25px;
                        width:25px;
                    }
                }

                .button-box {
                    button {
                        white-space: nowrap;
                        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                        border-radius: 1000px;
                        border:none;
                        color:#FDFDFF;
                        font-size:20px;
                        height:100%;
                        padding:0 30px;
                        margin-left:10px;

                        &.inactive {
                            cursor: default;
                            color:#FBFBFB7A;
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        }
                    }
                }
            }
        }
    }

    .bets {
        padding-top:10px;

        .bets-header {
            padding:0 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;

                h2 {
                    font-weight: 500;
                    margin-right:10px;
                }

                span {
                    color:#7D8496;
                    font-size:18px;
                }
            }

            .currency-filter {
                display: flex;
                align-items: center;

                > div {
                    display: flex;
                    align-items: center;
                    padding:3px 7px;
                    cursor: pointer;

                    &.inactive {
                        span, .float {
                            color:#7D8496 !important;
                        }
                    }

                    &:first-child {
                        padding-right:25px;
                        border-right: 2px solid #2e323d;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }

                    img {
                        height:30px;
                        margin-right:10px;

                        &.points {
                            height:55px;
                            margin-right:0;
                            margin-left:-5px;
                        }
                    }

                    span {
                        white-space: nowrap;
                        color:#FDFDFF;
                        font-size:19px;
            
                        .float {
                            color:#FDFDFF !important;
                        }
                    }
                }
            }
        }

        .bets-body {
            overflow-y: auto;
            overflow-x: hidden;
            margin-right:5px;

            &::-webkit-scrollbar {
                width:5px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #3B4256;
                border-radius: 1000px;
            }

            .bet {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height:90px;
                padding:0 40px 0 24px;
                
                &:nth-child(odd) {
                    background-color: #1E222C;
                }

                > div {
                    display: flex;
                    align-items: center;
                    width:33%;

                    &.multiplier {
                        justify-content: flex-end;

                        > div {
                            border-radius: 1000px;
                            padding: 9px 22px;
                            font-size: 15px;
                        }

                        .win {
                            color:#039700;
                            background: rgba(3, 151, 0, 0.1);
                            
                        }
                        .lose {
                            color:#C11B1B;
                            background: rgba(193, 27, 27, 0.1);
                        }
                    }

                    &:last-child {
                        justify-content: flex-end;

                        img {
                            margin-left:10px;
                            height:30px;
                            width:30px;

                            &.points {
                                height:60px;
                                width:60px;
                                margin-right:-15px;
                                margin-left:-5px;
                            }
                        }

                        span {
                            font-size:19px;
                            color:#FDFDFF;
                            
                            .float {
                                color:#7D8496;
                            }
                        }

                        .addup-value {
                            font-size:16px;
                            color: #7D8496;
                            margin-right:10px;
                        }
                    }
                }

                .pfp {
                    height:35px;
                    width:35px;
                    border-radius: 100%;
                    margin-right:20px;
                }

                .username {
                    font-size:19px;
                }

                .me {
                    font-size:20px;
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    border-radius: 1000px;
                    padding: 2px 12px;
                    margin-left:10px;
                }
            }
        }
    }

    @media only screen and (max-width: 1050px) {
        .game {
            #chart-container {
                width: calc(100% - 80px);
                height: calc(100% - 240px);
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        .past-games {
            //overflow-x: auto;
            padding-bottom:20px;
            margin-bottom:-10px;

            > div {
                padding: 15px 25px;
            }
        }

        .bets .bets-body {
            .bet {
                .multiplier {
                    display: none;
                }

                > div:last-child {
                    width: 100%;
                    margin-top:7px;

                    > .addup-value {
                        margin-left:20px;
                    }
                }
            }
        }

        .game {
            #chart-container {
                width: calc(100% - 90px);
                height: calc(100% - 240px);
            }
        }
    }

    @media only screen and (max-width: 586px) {
        .bets {
            .bets-header {
                flex-direction: column;
                align-items: flex-start;
        
                .currency-filter {
                    height:50px;
                    margin-top:-10px;
                    margin-bottom:30px;
        
                    > div { padding:0; }
                }
            }
            
            .bets-body {
                .bet {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height:100px;
        
                    > div {
                        &:first-child {
        
                            img {
                                height:40px;
                                width:40px;
                            }
                        }
                        &:last-child {
                            margin-left:50px;
                            flex-direction: row-reverse;
        
                            img {
                                margin-right:10px;
        
                                &.points {
                                    margin-top:-20px;
                                    margin-bottom:-20px;
                                    margin-right:-5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .game {
            .game-started {
                > div {
                    margin-top:-270px;

                    h2 {
                        margin-top:-70px;
                        font-size:27px;
                    }
                } 
            }
            .game-over {
                > div h2 {
                    margin-top:20px;
                    font-size:27px;
                }
            }

            #chart-container {
                height: calc(100% - 290px);
                width: calc(100% - 60px);
            }
        }
    }

    @media only screen and (max-width: 570px) {
        .submarine {
            height:200px !important;
        }
    }
}

//Bubble animations
@keyframes upToDown {
    0% {
        margin-top:0;
    }
    50% {
        margin-top:-20px;
    }
    100% {
        margin-top:0;
    }
}

@keyframes downToUp {
    0% {
        margin-top:0;
    }
    50% {
        margin-top:20px;
    }
    100% {
        margin-top:0;
    }
}

@keyframes bringBubbles {
    0% {
        top: 800px;
    }
    100% {
        top: 0;
    }
}

@keyframes bringSubmarine {
    0% {
        top: -200px;
    }
    100% {
        top: 50%;
    }
}

@keyframes sendSubmarine {
    0% {
        top: 50%;
    }
    100% {
        top: 200%;
    }
}

@keyframes sendBubbles {
    0% {
        top: 0;
    }
    100% {
        top: -800px;
    }
}

@keyframes boom {
    0% {
        margin-right: 150px;
        width: 0;
        opacity: 1;
    }
    20% {
        margin-right: 0;
        width: 300px;
        opacity: 1;
    }
    60% {
        margin-right: 0;
        width: 300px;
        opacity: 1;
    }
    100% {
        margin-right: -20px;
        width: 340px;
        opacity: 0;
    }
}