$header-height: 100px;

@mixin center-everything {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin center-absolute {
    left:0;
    right:0;
    margin: auto;
    top:50%;
    transform: translateY(-50%);
}

#right-nav {
    flex-shrink: 0;
    height: calc(100vh - $header-height);
    width:calc($header-height + 450px);
    display: flex;
    flex-direction: column;
    background-color: #1E222C;
    border-left: 1px solid #2E323D;
    z-index: 20;
    position: relative;

    //Not Expanded
    &.shrank {
        width:$header-height;
        z-index: 10;

        .item {
            width:100%;
            border-bottom:1px solid #2E323D;

            &:last-child {
                border:none;
            }
    
            a {
                height: calc($header-height + 10px);
                color:#7D8496;
                @include column-center;
    
                &:hover {
                    background-color: #2A2F3D;
                }
    
                img {
                    height:20px;
                }
    
                p {
                    margin:20px 0 0 0;
                    font-size: 18px;
                }
            }
        }
    }

    .right-nav-header {
        height:$header-height;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        border-bottom:1px solid #2E323D;

        .close-nav {
            display: flex;
            align-items: center;
            flex-grow: 1;
            padding:0 24px;

            button {
                border:none;
                background-color: #2A2F3D;
                height:40px;
                width:40px;
                border-radius: 100%;
                transition: 200ms;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color:#333948;
                }

                img {
                    height:15px;
                }
            }

            p {
                margin:0;
                margin-left:15px;
                color:#FDFDFF;
                font-weight: 500;
                font-size:24px;
                flex-grow: 1;
            }

            .online-count {
                display: flex;
                align-items: center;

                span {
                    color:#039700;
                    font-size:19px;
                    margin-right:3px;
                }

                .flick-effect {
                    height:24px;
                    width:24px;
                    position: relative;

                    > div:first-child {
                        border-radius: 100%;
                        background-color: #0397002d;
                        animation-name: flick;
                        animation-duration: 1.3s;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
                        position: absolute;
                        @include center-absolute;
                    }

                    > div:last-child {
                        height:10px;
                        width:10px;
                        border-radius: 100%;
                        background-color: #039700;
                        position: absolute;
                        @include center-absolute;
                    }
                }
            }
        }

        .language-selection {
            width:$header-height;
            height:100%;
            border-left:1px solid #2E323D;

            a {
                width:100%;
                height:100%;
                @include center-everything;

                &:hover {
                    background-color: #2A2F3D;
                }

                img {
                    height:25px;
                    border-radius: 100%;
                }
            }
        }
    }

    .rain-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom:1px solid #2E323D;
        flex-shrink: 0;
        padding:15px 20px 15px 36px;
        position: relative;
        cursor: pointer;

        &.rain-active {
            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%), linear-gradient(0deg, #2E323D, #2E323D);

            .rain-info .rain-timer {
                background-color: #FDFDFF1A;
            }

            .joined-text {
                margin-left:10px;
                font-size:17px;
            }

            .rain-amount span .float {
                color:white !important;
            }
        }

        .rain-info {
            display: flex;
            align-items: center;

            img {
                height:24px;
                margin-right: 20px;
            }

            span {
                color:#FDFDFF;
                font-size: 21px;
            }

            .rain-timer {
                background-color:#2A2F3D;
                border-radius:1000px;
                padding:6px 16px;
                margin-left:14px;

                span {
                    font-size:17px;
                }
            }
        }

        .rain-amount {
            img {
                margin-right:10px;
                height:32px;
                vertical-align: bottom;
            }

            span {
                white-space: nowrap;
                color:#FDFDFF;
                font-size:20px;

                .float {
                    color:#7D8496;
                }
            }
        }

        .tip {
            border-bottom: 2px solid #2E323D;
            border-top: 2px solid #2E323D;
            position: absolute;
            width:100%;
            background-color: #1E222C;
            left:0;
            top:68px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding:25px 0;
            cursor:default;

            .tip-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100% - 20px);
                margin-left:20px;
                padding: 0 0 30px 0;

                .close-button {
                    position: relative;
                    top:0;
                    right:20px;
                }

                > div {
                    display: flex;
                    align-items: center;

                    h3 {
                        font-size:28px;
                        font-weight: 500;
                        margin:0;
                        margin-right: 20px;
                    }

                    .rain-timer {
                        background: #2A2F3D;
                        border-radius: 1000px;
                        padding: 8px 23px;
                        font-size:18px;
                    }
                }
            }

            > div:not(.tip-header) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 90%;
                padding:10px;
                position: relative;

                img {
                    position: absolute;
                    left:25px;
                    height:25px;
                }

                input {
                    width: 100%;
                    background-color: #2A2F3D;
                    border:none;
                    border-radius: 1000px;
                    font-size: 18px;
                    color:white;
                    padding: 14px 20px 14px 55px;
                }

                button {
                    width:100%;
                    border: none;
                    color: white;
                    font-size:18px;
                    padding:15px 0;
                    border-radius: 1000px;
                    
                    &:first-child {
                        background-color: #2A2F3D;
                        margin-right:5px;
                    }
                    &:last-child {
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                        background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        margin-left:5px;
                    }
                }
            }
        }
    }

    //Mobile Chat
    &.expanded.mobile {
        display: flex;
        top:auto;
        bottom:0;
        z-index: 100;
        width:100%;
        right:0;
        border-radius: 24px 24px 0px 0px;

        .close-button {
            position: absolute;
            right:20px;
            top:20px;
            border-radius: 100%;
            border:none;
            width:50px;
            height:50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2A2F3D;
            z-index: 2;
            cursor: pointer;

            img {
                height:17px;
            }
        }

        .right-nav-header {
            .close-nav {
                flex-grow: 0;

                button { display:none; }
                p {
                    margin:0 20px 0 0;
                    font-size:28px;
                }
            }
        }

        @media only screen and (max-width: 500px) {
            .rain-container {
                padding:15px 20px;
            }
        }

        @media only screen and (max-width: 426px) {
            .rain-container {
                .rain-info {
                    img { margin-right: 7px; }
                    span { font-size:17px; }

                    .rain-timer {
                        padding:6px 10px;

                        span { font-size: 15px; }
                    }
                }

                .rain-amount {
                    > div {
                        display: flex;
                        align-items: center;

                        img {
                            height: 24px;
                            margin-right:4px;
                        }

                        span {
                            font-size:17px;
                        }
                    }
                }

                .joined-text {
                    font-size:15px;
                }
            }
        }
    }

    @media only screen and (max-width: 1450px) {
        &.expanded {
            position: absolute;
            right:0;
            top:$header-height;
            border-top: 1px solid #2E323D;
        }
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.language-menu.right-nav-lang {
    background-color: #1E222C;
    border-radius: 0px 0px 16px 16px;

    .item {
        width:100px;
        height:100px;
        transition:200ms;

        &:hover {
            background-color: #2A2F3D;
        }
        &:first-child {
            border-bottom:1px solid #2E323D;
        }
        &:last-child {
            border-radius: 0px 0px 16px 16px;
        }

        img { height:25px; }

        a {
            height:100%;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.right-nav-language-popup {
    .MuiPaper-root {
        margin-left: 17px;
    }
}

//Animations
@keyframes flick {
    0% {
        height:0;
        width:0;
    }
    80% {
        height:24px;
        width:24px;
    }
    100% {
        height:0px;
        width:0px;
    }
}