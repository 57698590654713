@import 'fonts.scss';

//Default font
* {
    font-family:"Poppins";
}

html {
    scroll-behavior: smooth;
}

body {
    margin:0;
    padding:0;
    background-color: #13161D;
    color:white;
    overflow: hidden;
}

a {
    text-decoration: none;
    color:white;
    transition:200ms;
    cursor: pointer;
}

input {
    outline: none;

    //Hiding arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder { color:#7D8496; }
}

button {
    cursor: pointer;
    outline: none;
}

$header-height: 100px;
#main {
    display: flex;
    
    main {
        //padding:24px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - $header-height);
        min-height: 100vh;
        flex-grow: 1;

        &:not(.no-bg) {
            background: url("../images/background.png") #13161D;
        }

        /*&.crash-bg {
            background: url("../images/crash-background.gif") #13161D;
            background-size: cover;

            @media only screen and (max-width: 1470px) {
                background-size: cover;
            }
        }*/

        > div {
            padding-bottom:100px;

            @media only screen and (max-width: 768px) {
                padding-bottom:220px;
            }
        }

        &::-webkit-scrollbar { width:10px; }
        &::-webkit-scrollbar-track { background: #1E222C; }
        &::-webkit-scrollbar-thumb { background: #163ea3; }
        &::-webkit-scrollbar-thumb:hover { background: #11399d; }
    }
}

.background-shadow {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background: rgba(19, 22, 29, 0.56);
    z-index: 10;
}

::selection {
    background-color: rgb(255, 255, 255);
    color:#11399d;
}

//MUI Overrides
.MuiPaper-root.MuiPaper-elevation {
    background-color: transparent;
}
.MuiButtonBase-root.MuiAccordionSummary-root,
.MuiAccordionDetails-root {
    padding:0 !important;
}
.MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
    //width: fit-content;
}
.MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
    border-radius: 10px;
    background-color: #2E323D;
    
    .MuiLinearProgress-bar {
        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
    }
}

//React-Data-Table-Component Overrides
.rdt_TableCol_Sortable {
    > span {
        display: none;
    }
}
div.rdt_Table {
    background-color: transparent;
    overflow-x: hidden;

    > div {
        background-color: transparent;
        color:white;
    }

    .rdt_TableHeadRow {
        background-color: transparent;
        color:#7D8496;
        font-size:18px;

        .rdt_TableCol:first-child {
            margin-left:10px;
        }
    }
    .rdt_TableBody {
        .rdt_TableRow {
            color:#FDFDFF;
            font-size:16px;

            &:nth-child(odd) {
                background-color: #1E222C;
            }
            &:nth-child(even) {
                background-color: transparent;
            }

            .rdt_TableCell {
                height:80px;
                
                //Containers inside row
                > div {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right:10px;
                    }

                    &:first-child {
                        margin-left:10px;
                    }
                }
            }
        }
    }
}

//react-toastify Overrides
div.Toastify__toast-container {
    width:500px;

    @media only screen and (max-width: 530px) {
        & {
            width:95%;
            margin:0 10px 10px 10px;

            > div {
                margin-top:10px;
            }
        }
    }
}
div.Toastify__toast {
    border: 1px solid #2E323D;
    box-shadow: 0px 24px 48px rgba(19, 22, 29, 0.24);
    border-radius: 0px 16px 16px 0px;

    .Toastify__toast-icon {
        display: none;
    }

    .amount {
        margin-left:5px;
        display: inline-flex;
        align-items: center;
        font-size:17px;

        span {
            color:white;
            
            .float {
                color:#7D8496;
            }
        }

        img {
            height:24px;
            margin-left:4px;
            margin-right:4px;

            &.points {
                height:40px;
                margin-bottom:-5px;
                margin-right:-5px;
                margin-left:-5px;
            }
        }
    }
}
div.Toastify__toast-theme--dark {
    background-color: #2A2F3D;
    padding:13px 13px 13px 20px;

    b {
        font-weight: 500;
        color:#FDFDFF;
        margin-bottom:4px;
    }

    span { color:#7D8496; }

    .Toastify__close-button {
        background-color: #3B4256;
        width:30px;
        height:30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }
}

div.Toastify__toast--error {
    border-left:5px solid #C11B1B;
}
div.Toastify__toast--success {
    border-left:5px solid #039700;
}
div.Toastify__toast--warning {
    border-left:5px solid #ecad00;
}
div.Toastify__toast--info {
    border-left:5px solid #2456D6;
}