#roulette {
    position: relative;
    min-height: calc(100vh - 50px);

    .controllers {
        padding: 12px 0;
        display: flex;
        flex-wrap: nowrap;

        button {
            padding:8px 30px;
            font-size:19px;
            border:none;
            border-radius: 1000px;
            background-color: #2A2F3D;
            color:#FDFDFF;
            margin:0 5px;
            transition:200ms;

            &:hover {
                background-color: #3B4256;
            }

            &:last-child {
                margin-right:-5px;
            }
        }
    }

    .past-games {
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom:20px;
        padding:24px 24px 0 24px;

        > div {
            border-radius: 1000px;
            padding:15px 45px;
            font-size:17px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            margin:0 5px;
            transition: 100ms;
            position: relative;
            z-index: 2;

            &.win {
                background-color: #1B2B1D;
                color:#039700;

                &:hover {
                    background-color: #1b361f;
                }
            }
            &.not-win {
                background-color: #1E222C;
                color:#7D8496;

                &:hover {
                    background-color: #29324a;
                }
            }
        }
    }

    #game {
        position: relative;
        display: flex;
        justify-content: center;
        height: 320px;

        .cases {
            position: absolute;
            display: flex;
            height: 100%;
            width: fit-content;
            left: 0;
            transition-timing-function: cubic-bezier(.15,.66,0,.98);
            
            .case {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    transition: 600ms;

                    &.gray {
                        filter: grayscale(1);
                    }
                }

                span {
                    font-size: 19px;
                }
            }
        }

        .line {
            position: absolute;
            // left: calc(15px + 100vw / 2 - 150px);
            margin-right: 45px;
            height: 300px;
            z-index: 6;

            @media only screen and (max-width: 768px) {
                margin-right: 0;
                height: 200px;
                width: 4px;
            }
        }

        .timer, .waiting {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            z-index: 4;

            span {
                color: #FDFDFF;
                font-size: 20px;
                font-weight: 500;
            }
        }

        @media only screen and (max-width: 768px) {
            height: 200px;
        }
    }

    .place-bet {
        box-sizing: border-box;
        width:100%;
        position: relative;
        z-index: 2;
        padding:0 24px;
        padding-bottom:30px;
        align-items: center;

        .amount-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #1E222C;
            padding:0 24px 0 0;
            height:65px;
            margin-bottom:20px;

            .currency-selection {
                display: flex;
                align-items: center;
                padding-right:10px;
                border-right: 1px solid #2E323D;
                height:100%;
                cursor: pointer;

                #currency-button {
                    width:90px;
                    padding-left:24px;
                }

                img.down {
                    height:8px;
                    margin-left:15px;
                    transition: 100ms;
                }
                img.robux {
                    height:30px;
                }
                img.points {
                    height:60px;
                    margin-left:-15px;
                    margin-right:-15px;
                }
            }

            .enter-bet-amount {
                padding:12px 0 12px 20px;
                flex-grow: 1;

                input {
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size:20px;
                    color:white;
                    -moz-appearance: textfield;
                }
            }
        }

        .multiplier-container {
            display: flex;
            align-items: center;
            height:65px;

            .multiplier {
                background-color: #1E222C;
                border-radius: 1000px;
                position: relative;
                width: 50%;
                height:100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding:0 24px;

                span {
                    color:#7D8496;
                    white-space: nowrap;
                    margin-right:100px;
                }

                input {
                    margin-top:-10px;
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    font-size:18px;
                    color:white;
                    -moz-appearance: textfield;
                }

                > div {
                    &:first-child {
                        width:100%;
                    }
                }
            }

            .profit {
                background-color: #1E222C;
                border-radius: 1000px;
                position: relative;
                width: 50%;
                height: 100%;
                min-width: 140px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 24px;
                margin-left:10px;

                .image {
                    height: 30px;
                    margin-right: 15px;

                    img {
                        height: 30px;
                        width: 30px;
                    }
                }

                .value {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex: 1;

                    p {
                        color: #7D8496;
                        font-size: 14px;
                        margin: 0;
                        font-size: 14px;
                    }

                    span {
                        color: #FDFDFF !important;
                        font-size: 16px;
                    }
                }

                .percent {
                    font-size: 15px;
                    color: #7D8496;
                }
            }

            .buttons {
                height:65px;
                display: flex;

                .info-box, .loop {
                    aspect-ratio: 1;
                    border-radius: 100%;
                    height:100%;
                    margin-left:10px;
                    background-color: #1E222C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition:200ms;
                    cursor: pointer;

                    &:hover, &.active {
                        background-color: #3B4256;
                    }

                    img {
                        height:25px;
                        width:25px;
                    }
                }

                .loop {
                    &.inactive {
                        cursor: default;

                        &:hover {
                            background-color: #1E222C;
                        }
                    }

                    &.bet-loop {
                        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                    }
                }

                .button-box {
                    button {
                        white-space: nowrap;
                        background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                        border-radius: 1000px;
                        border:none;
                        color:#FDFDFF;
                        font-size:20px;
                        height:100%;
                        width: 170px;
                        padding:0 30px;
                        margin-left:10px;

                        &.inactive {
                            cursor: default;
                            color:#FBFBFB7A;
                            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        }
                    }
                }
            }

            @media only screen and (max-width: 1320px) {
                flex-wrap: wrap;
                height: fit-content;

                .multiplier {
                    width: 100%;
                    margin-bottom: 20px;
                    height: 65px;
                }

                .profit {
                    flex: 1;
                    height: 65px;
                    margin-left: 0;
                }
            }

            @media only screen and (max-width: 830px) {
                .profit {
                    .image { display: none; }
                }
            }
        }

        @media only screen and (max-width: 777px) {
            .profit {
                margin-bottom: 20px;
            }
        }

        @media only screen and (max-width: 768px) {
            .profit {
                margin-bottom: 0;
            }
        }

        @media only screen and (max-width: 600px) {
            padding: 15px;

            .controllers {
                display: none;
            }
        }

        @media only screen and (max-width: 557px) {
            flex-wrap: wrap;

            .profit {
                margin-bottom: 20px;
                width: 100%;
            }
        }

        @media only screen and (max-width: 378px) {
            .buttons {
                width: calc(100% - 12px);

                > div:first-child { display: none; }
                .button-box, .button-box > button { width: 100%; }
                .loop { margin-left: 0 !important; }
            }
        }

        &.chat-visible {
            @media only screen and (max-width: 1770px) {
                .multiplier-container {
                    flex-wrap: wrap;
                    height: fit-content;
    
                    .multiplier {
                        width: 100%;
                        margin-bottom: 20px;
                        height: 65px;
                    }
    
                    .profit {
                        flex: 1;
                        height: 65px;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .bets {
        padding-top:10px;

        .bets-header {
            padding:0 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;

                h2 {
                    font-weight: 500;
                    margin-right:10px;
                }

                span {
                    color:#7D8496;
                    font-size:18px;
                }
            }

            .currency-filter {
                display: flex;
                align-items: center;

                > div {
                    display: flex;
                    align-items: center;
                    padding:3px 7px;
                    cursor: pointer;

                    &.inactive {
                        span, .float {
                            color:#7D8496 !important;
                        }
                    }

                    &:first-child {
                        padding-right:25px;
                        border-right: 2px solid #2e323d;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }

                    img {
                        height:30px;
                        margin-right:10px;

                        &.points {
                            height:55px;
                            margin-right:0;
                            margin-left:-5px;
                        }
                    }

                    span {
                        white-space: nowrap;
                        color:#FDFDFF;
                        font-size:19px;
            
                        .float {
                            color:#FDFDFF !important;
                        }
                    }
                }
            }
        }

        .bets-body {
            overflow-y: auto;
            overflow-x: hidden;
            margin-right:5px;

            &::-webkit-scrollbar {
                width:5px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #3B4256;
                border-radius: 1000px;
            }

            .bet {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height:90px;
                padding:0 40px 0 24px;
                
                &:nth-child(odd) {
                    background-color: #1E222C;
                }

                > div {
                    display: flex;
                    align-items: center;
                    width:33%;

                    &.multiplier {
                        justify-content: flex-end;

                        > div {
                            border-radius: 1000px;
                            padding: 9px 22px;
                            font-size: 15px;
                            background: #2E323D;
                            color: #7D8496;
                        }

                        .win {
                            color:#039700;
                            background: rgba(3, 151, 0, 0.1);
                            
                        }
                        .lose {
                            color:#C11B1B;
                            background: rgba(193, 27, 27, 0.1);
                        }
                    }

                    &:last-child {
                        justify-content: flex-end;

                        img {
                            margin-left:10px;
                            height:30px;
                            width:30px;

                            &.points {
                                height:60px;
                                width:60px;
                                margin-right:-15px;
                                margin-left:-5px;
                            }
                        }

                        span {
                            font-size:19px;
                            color:#FDFDFF;
                            
                            .float {
                                color:#7D8496;
                            }
                        }

                        .addup-value {
                            font-size:16px;
                            color: #7D8496;
                            margin-right:10px;
                        }
                    }
                }

                .pfp {
                    height:35px;
                    width:35px;
                    border-radius: 100%;
                    margin-right:20px;
                }

                .username {
                    font-size:19px;
                }

                .me {
                    font-size:20px;
                    background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    border-radius: 1000px;
                    padding: 2px 12px;
                    margin-left:10px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        .past-games {
            //overflow-x: auto;
            padding-bottom:20px;
            margin-bottom:-10px;

            > div {
                padding: 15px 25px;
            }
        }

        .bets .bets-body {
            .bet {
                .multiplier {
                    display: none;
                }

                > div:last-child {
                    width: 100%;
                    margin-top:7px;

                    > .addup-value {
                        margin-left:20px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 586px) {
        .bets {
            .bets-header {
                flex-direction: column;
                align-items: flex-start;
        
                .currency-filter {
                    height:50px;
                    margin-top:-10px;
                    margin-bottom:30px;
        
                    > div { padding:0; }
                }
            }
            
            .bets-body {
                .bet {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height:100px;
        
                    > div {
                        &:first-child {
        
                            img {
                                height:40px;
                                width:40px;
                            }
                        }
                        &:last-child {
                            margin-left:50px;
                            flex-direction: row-reverse;
        
                            img {
                                margin-right:10px;
        
                                &.points {
                                    margin-top:-20px;
                                    margin-bottom:-20px;
                                    margin-right:-5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}