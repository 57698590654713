#withdraw {
    h1 {
        font-weight: 600;
        color: #FDFDFF;
        font-size:40px;
        margin-left:24px;
        margin-right:24px;
        margin-bottom:0;
    }

    .withdraw-row {
        margin-top:30px;
        margin-bottom:50px;
        padding:0 24px;

        .row-header {
            display: flex;
            align-items: center;

            p {
                margin:0 20px 0 0;
                font-weight: 500;
                font-size:19px;
            }

            .line {
                flex-grow: 1;
                height:2px;
                background-color: #2E323D;
            }
        }

        .withdraw-item-container {
            margin-top:20px;
            grid-auto-flow: column;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap:25px;

            .withdraw-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #1E222C;
                border-radius: 16px;
                margin-right:24px;
                width: 100%;
                max-width: 600px;
                position: relative;
                transition: 200ms;
                overflow: hidden;
                aspect-ratio: 1.4;
                cursor: pointer;

                &.hide {
                    visibility: hidden;
                }
                &:hover {
                    background: #252b39;
                }

                img {
                    margin-top:-40px;
                    position: relative;
                    z-index: 2;

                    &[alt="robux"] {
                        height:60%;
                        margin-top:-60px;
                    }
                    &[alt="roblox items"] {
                        height:70%;
                    }
                }

                p {
                    position: absolute;
                    bottom:5px;
                    font-weight: 500;
                    font-size:18px;
                }
            }
        }
    }

    @media only screen and (max-width: 1592px) {
        &.chat-visible {
            .withdraw-row .withdraw-item-container .withdraw-item p {
                bottom:-5px;
            }
        }
    }

    @media only screen and (max-width: 1144px) {
        .withdraw-row {
            .withdraw-item-container {
                gap:15px;
            }
        }
    }

    @media only screen and (max-width: 1018px) {
        padding-bottom: 120px;

        .withdraw-row {
            .withdraw-item-container {
                grid-template-columns: repeat(2, 1fr);
                
                .withdraw-item:nth-child(1n) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                .withdraw-item:nth-child(2n) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
        }
    }

    @media only screen and (max-width: 573px) {
        h1 {
            font-size:35px;
        }

        .withdraw-row {
            .withdraw-item-container {
                .withdraw-item {
                    aspect-ratio: 0.9;

                    .bonus {
                        left:0;
                        right:0;
                        margin:auto;
                        width:fit-content;
                        padding:4px 20px;
                    }

                    img {
                        height: auto !important;

                        &[alt="robux"] {
                            width:80%;
                        }
                        &[alt="roblox items"] {
                            width:100%;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 350px) {
        .withdraw-row {
            .withdraw-item-container {
                .withdraw-item {
                    text-align: center;
                    
                    p {
                        font-size:19px;
                    }

                    .bonus {
                        padding: 0 15px;
                        font-size:14px;
                    }
                }
            }
        }
    }
}