#login {

    p {
        margin:0;
        position: relative;
        z-index: 2;
    }

    .login-container {
        display: flex;
        height:100%;
        position: relative;

        .close-button {
            position: absolute;
            right:0px;
            top:0px;
            border-radius: 100%;
            border:none;
            width:40px;
            height:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2A2F3D;
            cursor: pointer;
        }

        > div {
            width:50%;
            height:100%;
        }

        .image-container {
            padding-right:20px;
            display: flex;
            align-items: flex-end;
            position: relative;

            img {
                z-index: 1;
                left:-370px;
                height:125%;
                position: absolute;
            }

            p {
                color:#7D8496;
            }
        }

        .login-section {
            padding-left:20px;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .login-selection {
                h2 {
                    font-weight: 600;
                    font-size:35px;
                    margin:0;
                    margin-bottom:10px;
                }

                button {
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid #2E323D;
                    color:#7D8496;
                    font-weight: 500;
                    font-size:18px;
                    width:50%;
                    padding:10px 0 20px 0;
                    transition: 200ms;

                    &.active {
                        color:#FDFDFF;
                        border-color: #1F52D6;
                    }
                }
            }

            .inputs {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding:30px 0 30px 0;
                border-bottom:2px solid #3B4256;

                > div {
                    position: relative;
                    width:100%;

                    img:not(.see-password) {
                        position: absolute;
                        top:20px;
                        left:20px;
                        height:22px;
                    }

                    .see-password {
                        position: absolute;
                        top:20px;
                        right:24px;
                        height:20px;
                        cursor: pointer;
                    }

                    input:not(.checkbox) {
                        width:100%;
                        box-sizing: border-box;
                        font-size:20px;
                        color:white;
                        background-color: #2A2F3D;
                        border:none;
                        border-radius: 1000px;
                        padding:16px 60px 16px 60px;
                        margin-bottom:23px;
                        outline: none;
                        transition:200ms;

                        &:focus {
                            background-color: #3B4256;

                            &.animated-input {
                                padding-top:27px;
                                padding-bottom:4px;
                            }
                        }
                        &:valid {
                            &.animated-input {
                                padding-top:27px;
                                padding-bottom:4px;
                            }
                        }
                    }

                    .animated-input:focus ~ .label,
                    .animated-input:not(:focus):valid ~ .label {
                        top: 4px;
                        font-size:16px;
                        bottom: 10px;
                        opacity: 1;
                    }

                    .label {
                        position: absolute;
                        pointer-events: none;
                        left: 60px;
                        top: 16px;
                        color:#7D8496;
                        font-size:20px;
                        transition: 0.2s ease all;
                    }

                    .sign-in {
                        background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                        border:none;
                        border-radius: 1000px;
                        padding:17px 0;
                        width:100%;
                        font-size:18px;
                        transition: 200ms;
                        color: rgba(251, 251, 251, 0.48);

                        &:hover {
                            color:rgba(251, 251, 251, 0.796);
                            background: linear-gradient(215.71deg, #527eec -7.35%, #1a4fd5 57.6%, #2f62e2 110.3%), linear-gradient(258.68deg, #3e6bdd -25.12%, #1e51d4 57.73%, #0d318b 130.84%);
                        }
                    }

                    &.checkbox-container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom:20px;

                        .checkbox {
                            margin-right:10px;
                            -webkit-appearance: none;
                            position: relative;
                            width: 30px;
                            height: 30px;
                            border-radius: 5px;
                            background: #3B4256;
                            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                            cursor: pointer;

                            &:checked {
                                background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            }
                            &::before{
                                content: '';
                                position: absolute;
                                top: 55%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width:20px;
                                height: 20px;
                                pointer-events: none;
                                background-image: url('../../assets/images/check.svg');
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                            &::before{
                                display: none;
                            }
                            &:checked::before{
                                display: block;
                            }
                        }

                        span {
                            font-size:17px;
                            color:#7D8496;
                            
                            a {
                                background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%), linear-gradient(0deg, #7D8496, #7D8496);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }

                    &.signin-button-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        button:not(.sign-in) {
                            background: #2A2F3D;
                            border:none;
                            border-radius: 1000px;
                            padding:17px 0;
                            width:100%;
                            font-size:18px;
                            transition: 200ms;
                            color: #fff;
                            margin-top:15px;
                        }
                    }
                }
            }

            .bottom-text {
                margin-top:30px;
                color:#7D8496;
            }
        }
    }

    @media only screen and (max-width: 1350px) {
        .login-container {
            .image-container {
                img {
                    height:80%;
                    left:-70px;
                    top:50%;
                    transform: translateY(-50%);
                    margin-top:-40px;
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .login-container {
            .image-container { display:none; }
            .login-section {
                width:100%;
                padding:0;

                h2 {
                    font-size:26px !important;
                    font-weight: 500 !important;
                    margin-bottom:24px !important;
                }

                .inputs {
                    border-bottom: none;
                    padding-top:30px;
                    padding-bottom:0px;

                    .checkbox-container {
                        margin-top:10px;
                        margin-bottom:30px !important;
                    }
                }

                .bottom-text {
                    display: none;
                }
            }
        }
    }
}