$header-height: 100px;

@mixin column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#left-nav {
    flex-shrink: 0;
    height: calc(100vh - $header-height);
    width:$header-height;
    background-color: #1E222C;
    padding:0;
    border-right: 1px solid #2E323D;

    @include column-center;
    justify-content: space-between;

    .item {
        background-color: #1E222C;
        width:100%;
        border-bottom:1px solid #2E323D;
        position: relative;

        &.active {
            background-color: #2A2F3D;

            a {
                color: #FDFDFF;
                cursor: default;
                
                .light {
                    position: absolute;
                    width:100%;
                    height:100%;
                    top:-20px;
                }
            }
        }

        a {
            width:100%;
            height: calc($header-height + 10px);
            color:#7D8496;
            @include column-center;

            &:hover {
                background-color: #2A2F3D;
            }

            img {
                height:20px;
            }

            p {
                margin:20px 0 0 0;
                font-size: 18px;
            }
        }
    }

    .top-items, .bottom-items {
        width:100%;
        @include column-center;

        &.top-items {
            .item:last-child {
                border:none;
            }
        }

        &.bottom-items {
            .item:nth-child(2) img { //Flag
                border-radius: 100%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        & {
            display: none;
        }
    }
}

.language-menu.left-nav-lang {
    display: flex;
    background-color: #1E222C;
    height:110px;
    border-radius: 0px 16px 16px 0px;

    .item {
        width:110px;
        transition:200ms;
        border-right:1px solid #2E323D;

        &:hover {
            background-color: #2A2F3D;
        }
        
        &:last-child {
            border-radius: 0px 16px 16px 0px;
            border-right: none;
        }

        a {
            height:100%;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}