.game-item {
    width:100%;
    background-color: #1E222C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: relative;
    transition: 200ms;
    aspect-ratio: 1;
    cursor: pointer;

    &:hover {
        background-color: #272c39;
    }
    &.selected {
        background-color:#2A2F3D;
    }

    img.item-image {
        height:110px;
        margin-top:30px;
    }

    .price {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:0px;

        img {
            height:70px;
            margin-left:-20px;
            margin-right:-10px;
        }

        span {
            font-size:19px;
            white-space: nowrap;
            color:#FDFDFF;

            .float {
                color:#7D8496;
            }
        }
    }

    .item-name {
        color:#FFFFFF;
        font-weight: 500;
        margin-top:40px;
        font-size:19px;
    }

    .rarity {
        position: absolute;
        height:11px;
        width:11px;
        left:15px;
        top:15px;
        border-radius: 100%;
    }

    .selected {
        position: absolute;
        right:12px;
        top:12px;
        height:40px;
        width:40px;
    }

    @media only screen and (max-width: 479px) {
        img.item-image {
            height:80px;
        }
        .price {
            img {
                height:60px;
            }
            span {
                font-size:17px;
            }
        }
    }
}