#confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 150px);

    h1 {
        margin: 10px 0;
        font-size: 40px;
        text-align: center;
    }

    p {
        margin: 0 10px;
        font-size: 20px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        height: calc(100% - 230px);
    }
}