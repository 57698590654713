#privacy {
    padding:24px;
    
    h1 {
        font-weight: 600;
        font-size:40px;
        margin-bottom:-10px;
        margin-top:-10px;
    }

    h2 {
        font-weight: 500;
        margin-top:32px;
    }

    > div {
        padding:20px 0;
        border-bottom:1px solid #2E323D;

        &:last-child {
            border:none;
            padding-bottom:0;
        }

        h3 {
            margin-top:10px;
            font-weight: 500;
        }
    }

    p {
        color: #7D8496;
    }

    @media only screen and (max-width: 570px) {
        & {
            h1 {
                font-size:35px;
            }
            h2 {
                margin-top:10px;
                margin-bottom:0;
            }
            p {
                margin-bottom:10px;
            }
        }
    }
}