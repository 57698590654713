#bottom-nav {
    position: fixed;
    width:100%;
    bottom:0;
    left:0;
    height:80px;
    z-index: 50;
    background-color: #1E222C;
    display: none;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid #2E323D;

    .item {
        height:100%;
        margin:0;
        width:100px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height:100%;
            color:#7D8496;

            p {
                margin:5px 0;
            }

            img {
                height:24px;
                margin:5px 0;
            }
        }

        &.active {
            a {
                color: #FDFDFF;
                cursor: default;
            }
        }
    }

    @media screen and (max-width: 768px) {
        & {
            display: flex;
        }
    }
}