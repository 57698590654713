#authorize {
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size:56px;
        margin-bottom:0px;

        span {
            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    p {
        text-align: center;
        font-weight: 600;
        font-size:21px;
        padding:0 10px;
        margin-bottom:50px;
    }

    > .login-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            width:80%;
            max-width: 500px;
            box-sizing: border-box;
            font-size: 20px;
            color: white;
            background-color: #2A2F3D;
            border: none;
            border-radius: 1000px;
            padding: 16px 60px 16px 60px;
            margin-bottom: 23px;
            outline: none;
            transition: 200ms;
    
            &:focus {
                background-color: #3B4256;
            }
        }
    
        img:not(.see-password) {
            position: absolute;
            top:20px;
            left:50px;
            height:22px;
        }
    
        .see-password {
            position: absolute;
            top:20px;
            right:70px;
            height:20px;
            cursor: pointer;
        }

        button {
            background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
            border:none;
            border-radius: 1000px;
            padding:17px 0;
            width:80%;
            max-width: 500px;
            font-size:18px;
            transition: 200ms;
            color: rgba(251, 251, 251, 0.48);

            &:hover {
                color:rgba(251, 251, 251, 0.796);
                background: linear-gradient(215.71deg, #527eec -7.35%, #1a4fd5 57.6%, #2f62e2 110.3%), linear-gradient(258.68deg, #3e6bdd -25.12%, #1e51d4 57.73%, #0d318b 130.84%);
            }
        }
    }

    @media only screen and (max-width: 506px) {
        h1 { font-size: 34px; }
        p { font-size: 17px; }
    }
}