#chat {
    height:100%;
    position: relative;

    .chat-window {
        height:calc(100vh - 370px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-left:15px;
        margin-right:5px;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        
        .log {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 24px 0;
            color: #7D8496;
            font-size: 15px;

            p {
                margin: 0;

                &:last-child {
                    margin-top: 8px;

                    span { color: #FDFDFF; }
                }
            }
        }

        .warning {
            width: calc(100% - 80px);
            padding: 16px 24px;
            margin: 20px 10px;
            border-radius: 16px;
            border: 1px solid #F90;
            background: rgba(255, 153, 0, 0.16);

            h5 {
                color: #F90;
                font-size: 17px;
                font-weight: 500;
                margin: 0;
            }

            p {
                margin: 10px 0 0 0;
                font-size: 14px;
                color: #FDFDFF;
            }
        }

        .message {
            width:100%;
            margin:8px 0;
            display: flex;

            .message-info {
                position: relative;
                width: 45px;

                img {
                    height:40px;
                    width:40px;
                    margin-right:10px;
                    border-radius: 100%;
                    cursor: pointer;
                }
            }

            .message-content {
                width:100%;

                > div {
                    display: block;
                    margin-bottom: 8px;
                    font-size: 20px;

                    span {
                        display: block;
                    }
                }

                .message-text {
                    width: 100%;
                    max-width: 100%;
                    padding: 12px 20px;
                    font-size: 17px;
                    word-wrap: break-word;
                    word-break: break-all;

                    .tag {
                        display: inline;
                        color: #7D8496;
                        font-weight: 500;
                        cursor: pointer;

                        /*&.active {
                            background: linear-gradient(259deg, #4f92e9 -25.12%, #1e86e7 57.73%, #276ef1 130.84%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }*/
                    }
                }

                .sender-name {
                    font-size: 17px;
                }
            }

            &.sent {
                justify-content: flex-end;

                .message-info {
                    width:80px;

                    img {
                        margin-right:10px;
                        margin-left:40px;
                    }
                }

                .message-content {
                    padding-right: 5px;
                    width:calc(100% - 95px);

                    > div:first-child {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-right:-35px;

                        .title {
                            margin: 0 4px;
                        }
                    }

                    .message-text {
                        border-radius: 16px 0 16px 16px;
                        background-color: #3B4256;
                    }
                }

                .sender-name {
                    margin-left: 5px;
                }
            }

            &.received {
                .message-content {
                    padding-left: 5px;
                    width:calc(100% - 105px);

                    .message-text {
                        border-radius: 0px 16px 16px 16px;
                        background-color: #2A2F3D;
                    }

                    > div:first-child {
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: row-reverse;
                        align-items: center;
                        margin-right:-35px;

                        .title {
                            margin: 0 4px;
                        }

                        /*> .who {
                            margin-left:10px;
                        }*/
                    }
                }

                .sender-name {
                    margin-right: 5px;
                }
            }

            .who {
                border-radius: 1000px;
                padding:1px 10px;
                color:white;
                font-size: 16px;
                line-height: 27px;
                margin: 0 5px;
                width: fit-content;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;

                & { background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%); }
                &.mod { background: linear-gradient(258.68deg, #eb7404 -25.12%, #e24b05 57.73%, #f54b08 130.84%); }
                &.admin { background: linear-gradient(258.68deg, #9145e2 -25.12%, #9309d3 57.73%, #8002da 130.84%); }
                &.owner { background: linear-gradient(258.68deg, #f22602 -25.12%, #d40303 57.73%, #b00202 130.84%); }
                &.tagged {
                    padding: 1px 9px;
                    line-height: 23px;
                }
            }
        }

        &::-webkit-scrollbar {
            width:5px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #3B4256;
            border-radius: 1000px;
        }
    }

    .shadow {
        background: linear-gradient(179.48deg, #1E222C -16.16%, rgba(30, 34, 44, 0) 100%);
        //background: linear-gradient(to bottom, #1E222C, transparent);
        width:100%;
        height:30vh;
        position: fixed;
        z-index: 2;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .send-message {
        height:100px;
        border-top: 1px solid #2E323D;
        position: absolute;
        width:100%;
        background-color: #1E222C;
        bottom:0px;
        z-index: 2;
        display: flex;

        .language-selection {
            height:100%;
            padding-right:35px;
            padding-left:10px;

            img {
                height:27px;
            }
        }

        > form {
            display: flex;
            width: 100%;
            position: relative;

            > div {
                padding:0 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;

                &:first-child {
                    flex-grow: 1;
                }

                .suggestions-menu {
                    position: absolute;
                    overflow-y: auto;
                    overflow-x: hidden;
                    bottom: 35px;
                    width: calc(100% - 48px);
                    max-height: 170px;
                    border-radius: 16px;
                    border: 1px solid #2E323D;
                    background-color: #2A2F3D;
                    box-shadow: 0px 24px 48px 0px rgba(19, 22, 29, 0.24);
                    padding: 0 0 50px 0;

                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 6px;
                        padding: 10px 16px;
                        cursor: pointer;

                        &:hover {
                            background-color: #3B4256;
                        }

                        &:first-child {
                            .name p, .command p {
                                color: #FDFDFF;
                            }
                        }

                        p {
                            margin: 0;
                            color: #7D8496;
                        }

                        .name, .command {
                            display: flex;
                            align-items: center;

                            img {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }

                            p {
                                font-size: 15px;
                                line-height: 30px;
                            }
                        }

                        .command {
                            p {
                                margin-right: 16px;
                            }

                            .desc {
                                color: #7D8496;
                                font-size: 13px;
                            }
                        }

                        .buttons {
                            margin-left: 10px;

                            p {
                                font-size: 14px;
                                line-height: 28px;
                                white-space: nowrap;
                            }
                            
                            .icons {
                                display: flex;

                                img {
                                    height: 20px;
                                    margin-left: 10px;
                                    padding: 2px;

                                    &:hover {
                                        border-radius: 50%;
                                        background: #5b6480;
                                        transition: 200ms;
                                    }
                                }
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width:2px;
                    }
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #3B4256;
                        border-radius: 1000px;
                    }

                    @media only screen and (max-width: 768px) {
                        max-height: 280px;
                    }
                }

                .input-field {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width:100%;
                    height:60px;
                    background-color: #2A2F3D;
                    border-radius: 1000px;
                    transition: 200ms;

                    input {
                        position: relative;
                        background: transparent;
                        box-sizing: border-box;
                        height:100%;
                        width:100%;
                        color:white;
                        font-size:18px;
                        outline:none;
                        border:none;
                        padding:0 105px 0 24px;
                        z-index: 3;

                        &:focus {
                            padding-top:27px;
                            padding-bottom:6px;
                        }
                        &:valid {
                            padding-top:27px;
                            padding-bottom:6px;
                        }
                    }

                    input:focus ~ .label,
                    input:not(:focus).active ~ .label {
                        top: 6px;
                        font-size: 14px;
                        bottom: 10px;
                        opacity: 1;
                    }

                    .label {
                        height: 24px;
                        position: absolute;
                        z-index: 1;
                        pointer-events: none;
                        left: 25px;
                        top: 15px;
                        color: #7D8496;
                        font-size: 18px;
                        transition: 0.2s ease all;
                    }

                    .suggestion {
                        position: absolute;
                        z-index: 2;
                        left: 24px;
                        bottom: 6px;
                        color: #7D8496;
                        font-size: 18px;
                        font-weight: 300;
                    }
                }
        
                button {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right:50px;
                    background-color: transparent;
                    border:none;
                    z-index: 5;
                    cursor: pointer;
                }

                .title {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 90px;
                    height: 35px;
                    width: 35px;
                    z-index: 5;
                    border-radius: 50%;
                    background-color: #3B4256;
                    cursor: pointer;

                    img {
                        margin-top: 1px;
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .counter-date {
                display: flex;
                align-items: center;
                justify-content: center;
                color:#7D8496;
                font-size: 15px;
                width: 100%;
            }
        }
    }
}

#mini-profile {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 16px 0 16px 16px;
    border: 1px solid #2E323D;
    background: #1E222C;
    box-shadow: 0px 24px 48px 0px rgba(19, 22, 29, 0.24);

    .top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        p {
            color: #FDFDFF;
            font-size: 15px;
            margin-left: 10px;
        }
    }

    .nav {
        padding: 8px 0 0 0;
        display: flex;

        button {
            background-color: transparent;
            width: 100%;
            border: none;
            border-bottom: 2px solid #2E323D;
            color: #7D8496;
            padding: 0 20px 16px 20px;
            font-size: 15px;
            cursor: pointer;

            &.active {
                border-color: #3464DF;
                color: #FDFDFF;
            }

            @media only screen and (max-width: 500px) {
                padding: 0 10px 5px 10px;
            }
        }
    }

    .content {
        height: 250px;

        .main {
            display: flex;
            flex-direction: column;
            height: 100%;

            .stats {
                display: flex;
                justify-content: space-between;
                padding: 24px 20px 20px 20px;
    
                > .stat {
                    > span {
                        color: #7D8496;
                        font-size: 14px;
                    }
    
                    > .balance {
                        display: flex;
                        align-items: center;
                        margin-top: 2px;

                        img {
                            height: 28px;
                            margin-right: 8px;
                        }

                        span {
                            color:white;
                            
                            .float {
                                color:#7D8496;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 500px) {
                    flex-direction: column;

                    > .stat {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                    }
                }
            }
    
            .buttons {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
    
                button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    height: 33%;
                    border: none;
                    background-color: transparent;
                    font-size: 15px;
                    color: #7D8496;
                    padding: 0 20px;

                    &:hover {
                        background-color: #2A2F3D;
                        color: #FDFDFF;
                    }

                    &:last-child {
                        border-radius: 0 0 15px 15px;
                    }
                }
            }
        }

        .transactions {
            padding: 24px 20px 20px 20px;
            height: calc(100% - 30px);

            > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 25%;

                .info {
                    display: flex;
                    flex-direction: column;

                    .type {
                        color: #7D8496;
                        font-size: 13px;
                    }

                    .date {
                        color: #FDFDFF;
                        font-size: 14px;
                    }
                }

                .balance {
                    display: flex;
                    align-items: center;
                    margin-top: 2px;
    
                    img {
                        height: 28px;
                        margin-left: 8px;
                    }
    
                    span {
                        color:white;
                        
                        .float {
                            color:#7D8496;
                        }
                    }
                }
            }
        }

        .actions {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 24px 20px 20px 20px;
            height: calc(100% - 30px);

            > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;

                &:first-child { padding-top: 0; }
                &:last-child { padding-bottom: 0; }

                .info {
                    display: flex;
                    flex-direction: column;

                    .type {
                        color: #7D8496;
                        font-size: 13px;
                    }

                    .date {
                        color: #FDFDFF;
                        font-size: 14px;
                    }
                }

                .reason {
                    color: #FDFDFF;
                    font-size: 14px;
                    text-align: end;
                }
            }

            &::-webkit-scrollbar {
                width:5px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #3B4256;
                border-radius: 1000px;
            }
        }

        @media only screen and (max-width: 500px) {
            height: 400px;
        }
    }

    @media only screen and (max-width: 768px) {
        width: calc(100vw - 34px);
        border-radius: 16px;
    }
}

.delete-message {
    border-radius: 16px 0 16px 16px;
    padding: 12px 32px 12px 16px;
    border: 1px solid #2E323D;
    font-size: 15px;
    background: #1E222C;
    color: #C11B1B;
    transition: 200ms;
    cursor: pointer;

    &:hover {
        background-color: #2A2F3D;
        color: #fd0808;
    }
}

.titles-popover {
    .MuiPaper-root.MuiPaper-elevation {
        left: auto !important;
        top: auto !important;
        right: 89px;
        bottom: 30px;
        border-radius: 16px;

        .titles {
            box-shadow: 0px 24px 48px 0px rgba(19, 22, 29, 0.24);
            background: #2A2F3D;
            border: 1px solid #2E323D;
            width: 35px;
            height: 100%;
            min-height: 75px;
            overflow: hidden;

            .selectables {
                position: relative;
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 160px;
                padding: 4px 1px 0 1px;

                .title-name {
                    display: none;
                    position: fixed;
                    align-items: center;
                    justify-content: center;
                    min-width: 120px;
                    height: 60px;
                    margin-left: -140px;
                    margin-top: 65px;
                    color: #FDFDFF;
                    box-shadow: 0px 24px 48px 0px rgba(19, 22, 29, 0.24);
                    border-radius: 16px 0px 16px 16px;
                    border: 1px solid #2E323D;
                    background: #1E222C;
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 35px;
                margin-bottom: 5px;
                cursor: pointer;

                &:hover {
                    .title-name {
                        display: flex;
                    }
                }

                img {
                    margin-top: 1px;
                    height: 20px;
                    width: 20px;
                }
            }

            > .title {
                margin-bottom: 0;
                border-radius: 50%;
                background-color: #3B4256;
            }
        }

        &::-webkit-scrollbar {
            width:2px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #3B4256;
            border-radius: 1000px;
        }

        @media only screen and (max-width: 768px) {
            right: 134px;
            bottom: 31px;
        }
    }
}