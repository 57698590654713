#deposit {
    padding-bottom:80px;

    .deposit-header {
        padding:24px 24px 0 24px;

        h1 {
            font-weight: 600;
            color: #FDFDFF;
            font-size:40px;
            margin:0 20px 0 0;
        }

        #country-button {
            background-color: #2A2F3D;
            margin-top:20px;
            width:360px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: none;
            padding: 3px 0 3px 10px;
            border-radius: 18px;
            font-weight: 400;
            font-family: 'Poppins';

            .text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .top-text {
                    color:#7D8496;
                    margin-bottom:-10px;
                    font-size: 13px;
                }
    
                .country-name {
                    color:white;
                    font-size:17px;
                }
            }

            img:not(.flag) {
                transition: 200ms;
                height:8px;
                margin-left:10px;
                position: absolute;
                right:20px;

                &.opened {
                    transform: rotateZ(180deg);
                }
            }

            .flag {
                border-radius: 100%;
                height:22px;
                margin-right: 10px;
                aspect-ratio: 1;
            }
        }
    }

    .deposit-row {
        margin-top:30px;
        margin-bottom:50px;
        padding:0 24px;

        .row-header {
            display: flex;
            align-items: center;

            p {
                margin:0 20px 0 0;
                font-weight: 500;
                font-size:19px;
            }

            .line {
                flex-grow: 1;
                height:2px;
                background-color: #2E323D;
            }
        }

        .deposit-item-container {
            margin-top:20px;
            grid-auto-flow: column;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap:25px;

            .deposit-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #1E222C;
                border-radius: 16px;
                margin-right:24px;
                width: 100%;
                max-width: 600px;
                position: relative;
                transition: 200ms;
                overflow: hidden;
                aspect-ratio: 1.4;
                cursor: pointer;

                &.hide {
                    visibility: hidden;
                }
                &:hover {
                    background: #252b39;
                }

                img {
                    margin-top:-40px;
                    position: relative;
                    z-index: 2;

                    &[alt="robux"] {
                        height:60%;
                        margin-top:-60px;
                    }
                    &[alt="roblox items"] {
                        height:70%;
                    }
                    &[alt="visa"] {
                        height:20%;
                    }
                    &[alt="mastercard"] {
                        height:40%;
                    }
                    &[alt="qiwi"] {
                        height:55%;
                        margin-top:-30px;
                    }
                    &[alt="perfectmoney"] {
                        height: 50%;
                        filter: drop-shadow(0px 0px 30px rgba(107, 1, 1, 0.477));
                    }
                    &[alt="megafon"] {
                        height: 50%;
                        filter: drop-shadow(0px 0px 20px rgba(43, 255, 1, 0.141));
                    }
                    &[alt="yoomoney"] {
                        height: 50%;
                        filter: drop-shadow(0px 0px 20px rgba(125, 0, 214, 0.365));
                    }
                    &[alt="litecoin"], &[alt="doge"], &[alt="bitcoin"] {
                        height:50%;
                    }

                    //Light customization
                    &[alt="mastercard-light"] {
                        margin-top:20px;
                    }
                    &[alt="qiwi-light"] {
                        height:110%;
                    }
                    &.crypto-light {
                        height:110%;
                        margin-top:20px;
                    }
                }

                .light {
                    position: absolute;
                    height:100%;
                    z-index: 1;
                }

                p {
                    position: absolute;
                    bottom:5px;
                    font-weight: 500;
                    font-size:18px;
                }

                .bonus {
                    position: absolute;
                    top:10px;
                    right:10px;
                    padding:10px 20px;
                    color:white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #2A2F3D;
                    border-radius: 1000px;
                    font-size: 17px;
                }
            }
        }
    }

    @media only screen and (max-width: 1592px) {
        &.chat-visible {
            .deposit-row .deposit-item-container .deposit-item p {
                bottom:-5px;
            }
        }
    }

    @media only screen and (max-width: 1144px) {
        .deposit-row {
            .deposit-item-container {
                gap:15px;
            }
        }
    }

    @media only screen and (max-width: 1018px) {
        padding-bottom: 120px;

        .deposit-row {
            .deposit-item-container {
                grid-template-columns: repeat(2, 1fr);
                
                .deposit-item:nth-child(1n) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                .deposit-item:nth-child(2n) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
        }

        .games {
            grid-template-columns: auto auto;
        }
    }

    @media only screen and (max-width: 573px) {
        h1 {
            font-size:35px;
        }
        
        .games {
            gap:15px;

            .game {
                img {
                    margin-bottom:40px;
                }
                p {
                    width:80%;
                    bottom:20px;
                    font-size: 17px;
                    white-space: nowrap;
                    overflow:hidden; 
                    white-space:nowrap; 
                    text-overflow: ellipsis;
                }
            }
        }

        .deposit-row {
            .deposit-item-container {
                .deposit-item {
                    aspect-ratio: 0.9;

                    .bonus {
                        left:0;
                        right:0;
                        margin:auto;
                        width:fit-content;
                        padding:4px 20px;
                    }

                    img {
                        height: auto !important;

                        &[alt="robux"] {
                            width:80%;
                        }
                        &[alt="roblox items"] {
                            width:100%;
                        }
                        &[alt="visa"] {
                            width:70%;
                        }
                        &[alt="mastercard"] {
                            width:70%;
                        }
                        &[alt="qiwi"] {
                            width:55%;
                            margin-top:-30px;
                        }
                        &[alt="litecoin"], &[alt="doge"], &[alt="bitcoin"] {
                            width:50%;
                            margin-top:-10px;
                        }

                        //Light customization
                        &[alt="visa-light"] {
                            display: none;
                        }
                        &[alt="mastercard-light"] {
                            margin-top:20px;
                            width:120%;
                        }
                        &[alt="qiwi-light"] {
                            width:120%;
                        }
                        &.crypto-light {
                            width:110%;
                            margin-top:20px;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 418px) {
        .deposit-header #country-button {
            width: calc(100vw - 55px);
        }
    }

    @media only screen and (max-width: 376px) {
        .deposit-row {
            .deposit-item-container {
                .deposit-item {
                    text-align: center;
                    
                    p {
                        font-size:19px;
                    }

                    .bonus {
                        padding: 0 15px;
                        font-size:14px;
                    }
                }
            }
        }
    }
}

.select-game {
    padding:24px;
    
    .game-search {
        margin-right:0 !important;
        margin-top:-10px;
    }

    .header {
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            align-items: center;

            h1 {
                font-weight: 600;
                font-size:40px;
                margin:0 !important;
            }
            
            .tag {
                color:#FDFDFF;
                padding:6px 26px;
                background-color: #1E222C;
                border-radius: 1000px;
                margin-left:20px;
                font-size:18px;
                transition: 200ms;
                cursor: pointer;

                &:hover {
                    background-color: #2d323f;
                }
            }

            .go-back {
                border:none;
                background-color: #2A2F3D;
                height:40px;
                width:40px;
                margin-top: -10px;
                border-radius: 100%;
                transition: 200ms;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color:#333948;
                }

                img {
                    height:15px;
                    transform: rotateZ(180deg);
                }
            }
        }

        a {
            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size:18px;
            margin-right:10px;

            img {
                vertical-align: middle;
                margin-right:10px;
                height:25px;
            }
        }
    }

    .search {
        margin-top:20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;

            &.search {
                position: relative;
                flex-grow: 1;
                margin-right:15px;

                input {
                    width:100%;
                    padding:0 0px 0 55px;
                    background-color: #1E222C;
                    border:none;
                    border-radius: 1000px;
                    outline:none;
                    color:white;
                    font-size:20px;
                    height:100%;
                }
        
                .icon {
                    position: absolute;
                    height:23px;
                    bottom:20px;
                    left:20px;
                }
            }

            &.sort {
                background-color: #1E222C;
                padding:0 30px;
                border-radius: 1000px;
                display: flex;
                align-items: center;
                margin-right:15px;
                cursor: pointer;

                img {
                    margin-left:10px;
                }
            }

            &.reload {
                background-color: #1E222C;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                aspect-ratio: 1;
                cursor: pointer;

                img {
                    height:23px;
                }
            }
        }
    }

    .games {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 25px;
        margin-top:20px;

        .game {
            width:100%;
            background-color: #1E222C;
            border-radius: 16px;
            position: relative;
            padding:8px;
            transition: 200ms;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: #272c39;
            }

            img {
                width:100%;
                margin-bottom:100px;
                border-radius: 16px 16px 0 0;
            }

            p {
                text-align: center;
                font-weight: 500;
                color: #FFFFFF;
                font-size:20px;
                position:absolute;
                width:100%;
                left:0;
                right:0;
                margin: auto;
                bottom:40px;
            }
        }
    }

    @media only screen and (max-width: 968px) {
        .header > div {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                font-size:34px !important;
            }

            .tag {
                margin:10px 0 0 0;
            }
        }
    }
    
    @media only screen and (max-width: 350px) {
        .games {
            grid-template-columns: auto !important;
        }
    }
}

.confirm-modal {
    width: 100%;
    position: relative;

    h1 {
        color: #FDFDFF;
        font-size: 40px;
        font-weight: 600;
        margin:0;
    }

    p {
        margin-top:30px;
        color:#FDFDFF;
        font-size:21px;
        display: flex;

        div {
            margin-left:10px;
            display: flex;

            span {
                white-space: nowrap;
                color:#FDFDFF;
    
                .float {
                    color:#7D8496;
                }
            }

            img {
                height:60px;
                margin:-15px -5px 0 -10px;
            }
        }
    }
}

.deposit-modal {
    .close-button {
        position: absolute;
        right:-15px;
        top:0px;
        border-radius: 100%;
        border:none;
        width:40px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2A2F3D;
        z-index: 10;
        cursor: pointer;
    }

    .buttons {
        display: flex;
        width: 100%;

        button {
            border:none;
            border-radius: 1000px;
            font-size:18px;
            padding:15px 30px;
            background-color: #2A2F3D;
            transition:200ms;
            color:white;
            width: 100%;
            margin-top:30px;
            margin-right:10px;

            &:hover {
                background-color: #363e52;
            }

            &.active {
                margin-right:0;
                margin-left:10px;
                background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);

                &:hover {
                    background: linear-gradient(258.68deg, #3965d6 -25.12%, #1b4cc8 57.73%, #0d2e84 130.84%);
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        h1 {
            font-size:30px;
        }

        p {
            margin:10px 0 0 0;
        }

        .buttons {
            flex-direction: column;
            margin-top:30px;

            button {
                display: block;
                margin:0 0 10px 0 !important;
            }
        }
    }
}

.visa-modal {
    position: relative;

    .inputs {
        .robux-deposit-search {
            padding: 0;
            margin-top:0px;

            .search {
                margin-right:0;

                input { margin-top:0; }
            }

            .controllers {
                button {
                    background-color: #3B4256 !important;
                }
            }
        }

        > div {
            margin-top:30px;

            &.card-info-2 {
                display: flex;

                input:first-child {
                    margin-right:7px;
                }
                input:last-child {
                    width:40%;
                    margin-left:7px;
                }
            }

            &.total {
                display: flex;
                align-items: center;
                height:60px;
                justify-content: space-between;
                margin-top: 30px;
                padding-bottom:30px;
                padding-top: 30px;
                border-bottom: 1px solid #2E323D;
                border-top: 1px solid #2E323D;

                p {
                    font-size:23px;
                    font-weight: 500;
                    margin: 0;
                }

                span {
                    white-space: nowrap;
                    color:#FDFDFF;
                    font-size: 18px;
        
                    .float {
                        color:#7D8496;
                    }
                }
            }
        }

        input {
            background-color: #2A2F3D !important;
            width:100%;
            padding:0 30px 0 30px;
            border:none;
            border-radius: 1000px;
            outline:none;
            color:white;
            font-size:20px;
            height:100%;
            box-sizing: border-box;
            height:60px;
        }
    }

    .close-button {
        top:0px !important;
        right:-10px !important;
        transition: 200ms;

        &:hover {
            background-color: #3d4456;
        }
    }

    @media only screen and (max-width: 768px) {
        h1 {
            font-weight: 500;
            font-size: 25px;
            margin:30px 0;
        }

        .card-info-2 {
            input {
                width:50% !important;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .robux-deposit-search {
            .search {
                .controllers {
                    button:nth-child(2) { display:none; }
                }
            }
        }

        .buttons {
            flex-direction: column-reverse;

            button {
                font-size:21px;
            }
        }
    }
}

#country-menu {
    margin-top:-10px;

    .country-name-item {
        background-color: #2A2F3D;
        color:white;
        padding: 13px 0 13px 10px;
        font-size:18px;
        width:360px;
        font-weight: 400;
        font-family: 'Poppins';
        margin-top:-1px;

        img {
            border-radius: 100%;
            margin-right:10px;
            height: 22px;
            width: 22px;
        }

        &:last-child {
            border-radius: 0 0 18px 18px;
        }
        &:hover {
            background-color: #252936 !important;
        }
    }

    @media only screen and (max-width: 418px) {
        .country-name-item {
            width: calc(100vw - 55px);
        }
    }
}