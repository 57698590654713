#faq {
    padding:24px !important;
    
    .faq-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-weight: 600;
            font-size:40px;
            margin-top:-10px;
            margin-bottom:-10px;
        }

        a {
            background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size:18px;

            img {
                vertical-align: middle;
                margin-right:10px;
            }
        }
    }

    .faq-search {
        position: relative;
        
        p {
            color:#7D8496;
            font-size:18px;
        }

        input {
            width:calc(100% - 54px);
            padding:16px 0px 16px 55px;
            background-color: #1E222C;
            border:none;
            border-radius: 1000px;
            outline:none;
            color:white;
            font-size:20px;
        }

        .icon {
            position: absolute;
            bottom:20px;
            left:20px;
        }
    }

    .faq-content {
        .accordion-title {
            color:#FDFDFF;
            font-weight: 500;
            font-size:18px;
            padding:15px 0;
            font-family: "Poppins";
        }

        .accordion-text {
            border-left:2px solid #1F52D6;
            padding:0px 20px;
            margin:15px 0;
            margin-top:-20px;
            font-size:19px;
            font-family: "Poppins";

            p {
                margin-left: 25px;
            }

            li {
                line-height: 50px;
            }
        }
    }

    @media only screen and (max-width: 382px) {
        .faq-content .accordion-title {
            font-size: 16px;
        }

        .faq-header {
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin-bottom: 5px;
            }
        }
    }
}