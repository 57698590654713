.robux-deposit {
    padding: 0;

    h1 {
        font-weight: 600;
        color: #FDFDFF;
        font-size:40px;
        margin-left:24px;
        margin-right:24px;
        margin-bottom:0;
    }

    .robux-deposit-header {
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin:25px 0;

        h2 {
            font-weight: 500;
            color:#FDFDFF;
            font-size:25px;
            margin:0 20px 0 0;
        }
    }

    .robux-deposit-header {
        h2 {
            margin:0 0 20px 24px;
        }
    }
}

.robux-deposit-search {
    margin-top:37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 24px 36px 24px;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;

        &.search {
            position: relative;
            flex-grow: 1;
            margin-right:15px;

            input {
                width:100%;
                padding:0 0px 0 60px;
                background-color: #1E222C;
                border:none;
                border-radius: 1000px;
                outline:none;
                color:white;
                font-size:20px;
                height:100%;
            }
    
            .icon {
                position: absolute;
                height:23px;
                bottom:16px;
                left:20px;
                height:27px;
            }

            .controllers {
                display: flex;
                align-items: center;
                position: absolute;
                right: 20px;

                button {
                    margin-right:10px;
                    padding:8px 30px;
                    font-size:19px;
                    border:none;
                    border-radius: 1000px;
                    background-color: #2A2F3D;
                    color:#FDFDFF;
                    margin:0 5px;
                    transition:200ms;
        
                    &:hover {
                        background-color: #3B4256;
                    }
                }

                img {
                    height:22px;
                    margin-left:10px;
                    margin-right:10px;
                }
            }
        }

        &.buttons {
            button {
                background: linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                border-radius: 1000px;
                color:white;
                height:100%;
                padding:0 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                font-size:18px;
            }
        }
    }

    @media only screen and (max-width:650px) {
        margin-top:20px;
        flex-direction: column;

        > div {
            width:100%;

            &.search {
                .controllers {
                    button {
                        font-size:17px;
                        padding:2px 20px;
                    }
                    button:nth-child(2) {
                        display: none;
                    }
                }
            }

            &.buttons {
                margin-top:20px;

                button {
                    width:100%;
                }
            }
        }
    }
}