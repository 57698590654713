$header-height: 100px;
$header-mobile-height: 70px;

#hamburger {
    background-color: #1E222C;
    position: fixed;
    z-index: 1000;
    top: $header-height + 2px;
    left:0;
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:20px;
    box-sizing: border-box;

    .user-data {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #2E323D;
        width:100%;
        padding-bottom:20px;

        a img {
            border-radius: 100%;
            height:43px;
            width:43px;
            margin-right:15px;
        }

        .currencies {
            > div {
                img:not(.points) { height:25px; }
                img.points { height:45px; }
                span { font-size:16px; }
            }
        }
    }

    ul {
        list-style: none;
        padding:10px 0;
        width:100%;

        li {
            margin-bottom:30px;

            &.rakeback {
                a {
                    color:#FF9900;
                }
            }

            a {
                display: flex;
                align-items: center;
                font-size:19px;
                color:#7D8496;

                img {
                    height:24px;
                    margin-right:10px;
                }
            }
        }

        &.list-one {
            border-bottom: 2px solid #2E323D;
        }

        &.list-two {

        }
    }

    @media only screen and (max-width: 768px) {
        top: $header-mobile-height + 2px;
    }
}