.currencies {
    display: flex;
    align-items: center;
    justify-content: center;
    
    > div {
        padding:4px 20px 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:20px;

        img {
            vertical-align: bottom;
            margin-right:10px;
        }

        span {
            white-space: nowrap;
            color:#FDFDFF;

            .float {
                color:#7D8496;
            }
        }

        &:first-child {
            border-right:2px solid #2E323D;
            img { height:30px; }
        }
        &:nth-child(2) {
            padding-left:30px;
            img { margin:0 -5px 0 -24px; }
        }
    }
    
    .what {
        height:25px;
        margin-right:30px;
        cursor: pointer;
    }
}