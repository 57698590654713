$header-height: 100px;
$header-mobile-height: 70px;

header {
    background-color: #1E222C;
    border-bottom: 1px solid #2E323D;
    position: relative;
    height: $header-height;
    z-index: 2;

    > div {
        display: flex;
        align-items: center;
    }

    .large-header {
        .header-logo-container, .user-avatar-container, .logout-container {
            // These values shouldn't change in different screen sizes
            // Only will change in mobile resolution
            width:$header-height;
            height:$header-height;
            min-width: $header-height;
            min-height: $header-height;
    
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
    
            &.header-logo-container {
                background-color: #3B4256;

                a {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        height: 70%;
                    }
                }
            }
    
            &.user-avatar-container {
                border-left:1px solid #2E323D;
    
                img:not(.light) {
                    width:50px;
                    height:50px;
                    border-radius: 100%;
                    transition:200ms;
    
                    &:hover {
                        width:52px;
                        height:52px;
                    }
                }
    
                .light {
                    position: absolute;
                    left:-30px;
                    top:0;
                    z-index: 0;
                }
    
                a {
                    position: relative;
                    z-index: 1;
                }
            }
            
            &.logout-container {
                border-left:1px solid #2E323D;
    
                img {
                    width: 120%;
                }
            }
        }
    
        .header-content {
            flex:1; //Fill the remaining space
            display: flex;
            justify-content: space-between;
    
            .header-content-routes {
                display: flex;
                align-items: center;
                justify-content: center;
    
                > div {
                    height:$header-height;
                    padding:0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    &:nth-child(2) {
                        margin-left:20px;
                    }
    
                    a {
                        font-size:20px;
                        color:#7D8496;
                        
                        &:hover {
                            color:#b0b4be;
                        }
    
                        span.active {
                            color:#FDFDFF;
                        }
                    }
    
                    img:not(.light), .icon { //Images near the header anchors
                        height:20px;
                        margin-right:10px;
                        vertical-align: middle;
                    }
                }
                
                .rakeback-container {
                    padding:0 40px;
                    border-right:1px solid #2E323D;
                    position: relative;
    
                    .light { // <-- Not in use
                        position: absolute;
                        top:-10px;
                        z-index: 0;
                        opacity: .5;
                        transition: 200ms;
                    }
    
                    .bg-light {
                        opacity: 0.10;
                        filter: blur(26px);
                        border-radius: 100%;
                        height: 60px;
                        width: 120px;
                        left:0;
                        right:0;
                        margin: auto;
                        position: absolute;
                        z-index: 1;
                        top:20px;
                        background-color: #FF9900;
                        transition: 200ms;
                    }
    
                    .orange-circle {
                        position: absolute;
                        background-color: #FF9900;
                        border-radius: 100%;
                        height:10px;
                        width:10px;
                        right:35px;
                        top:23px;
                    }
                    
                    a {
                        //text-shadow: 0 0 30px #ff99008f, 0 0 53px #ff990090, 0 0 80px #ff990091;
                        color:#FF9900;
                        transition:400ms ease-in-out;
        
                        &:hover {
                            //text-shadow: 0 0 30px #ff9900bb, 0 0 53px #ff9900bd, 0 0 80px #ff9900b8;
                            color:#FF9900;
    
                            .light { opacity: 1; }
                            .bg-light { opacity: 0.13; }
                        }
                    }
                }
            }
    
            .header-balance {
                display: flex;
                align-items: center;
                justify-content: center;
    
                .deposit-withdraw-buttons {
                    align-items: center;
                    display: flex;
                    margin-right:30px;
    
                    a {
                        width:60px;
                        height:60px;
                        border-radius: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 50px;
                        margin:0 6px;
    
                        &:first-child {
                            background-color:#2A2F3D;
    
                            &:hover {
                                background-color:#363b49;
                            }
                        }
                        &:last-child {
                            background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            border: 1px solid;
                            border-image-source: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%);
                            box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
    
                            &:hover {
                                background: linear-gradient(215.71deg, #7497ee -7.35%, #3764d6 57.6%, #4470de 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                            }
                        }
                    }
                }
            }
        }
    }

    //Mobile Header
    .header-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height:100%;
        padding-right:20px;
        border-bottom: 2px solid #2E323D;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height:100%;

            &.start {
                .header-logo-container {
                    height:100%;
                    background-color: #3B4256;
                    aspect-ratio: 1;

                    a {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        img {
                            height: 70%;
                        }
                    }
                }
    
                .language-selection {
                    width:$header-mobile-height;
                    height:100%;
    
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width:100%;
                        height:100%;
                    }
    
                    img {
                        height:30%;
                    }
                }
            }

            &.end {
                .balance {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-right: 3vw;

                    img {
                        margin-left:10px;
                        height:40%;
                    }

                    span {
                        color:white;
                        
                        .float {
                            color:#7D8496;
                        }
                    }
                }

                .sign-in {
                    height:40px;
                    padding:0 20px;
                    margin-right:5px;
                    white-space: nowrap;
                }

                .hamburger-container {
                    height:100%;
                    display: flex;
                    align-items: center;
                    margin-left:5px;

                    img {
                        height:11%;
                        cursor: pointer;

                        &.close {
                            height:14px;
                        }
                    }
                }
            }
        }
    }

    .sign-in {
        display: flex;
        align-items: center;
        background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
        border: 1px solid;
        border-image-source: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%);
        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
        height:55px;
        padding:0 30px;
        border-radius: 1000px;
        font-size:20px;
        color:#FDFDFF;
        margin-right:30px;

        &:hover {
            background: linear-gradient(215.71deg, #678ce9 -7.35%, #2b5bd5 57.6%, #3d6adc 110.3%), linear-gradient(258.68deg, #4e78e1 -25.12%, #2b5ad2 57.73%, #173786 130.84%);
        }
    }

    //Responsive
    @media only screen and (max-width: 1450px) {
        .header-content .header-balance > .deposit-withdraw-buttons {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1283px) {
        z-index: 200;

        .large-header {
            display: none;
        }
        .header-mobile {
            display: flex;

            > div.end .balance { font-size: 23px; }
        }
    }
    @media only screen and (max-width: 768px) {
        height: $header-mobile-height;

        .header-mobile {
            padding-left:20px;

            > div.end .balance { font-size: 17px; }
        }
    }
}

.language-menu.right-nav-lang.header-nav .item {
    height:$header-mobile-height;
    width:$header-mobile-height;
}