#rakeback {
    position: relative;
    padding:30px 44px;

    .close-button {
        position: absolute;
        right:30px;
        top:30px;
        border-radius: 100%;
        border:none;
        width:40px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2A2F3D;
        z-index: 10;
        cursor: pointer;

        img {
            height:15px;
        }
    }

    .rakeback-header {
        display: flex;
        align-items: center;
        margin-bottom:30px;

        h1 {
            margin: 0;
            margin-right:20px;
            font-size:40px;
            font-weight: 600;
        }

        img {
            height:25px;
        }
    }

    .rakeback-body {
        padding-bottom:30px;
        border-bottom: 1px solid #2E323D;

        > div:first-child {
            margin-bottom:20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                display: flex;
                align-items: center;

                img {
                    width:24px;
                }

                span {
                    color:#FDFDFF;
                    font-size:19px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                &:first-child {
                    img { margin-right:10px; }
                }
                &:last-child {
                    img { margin-left:10px; }
                }
            }
        }
        
        .progress-container {
            position: relative;

            .circle {
                position: absolute;
                border-radius: 100%;
                height:10px;
                width:10px;
                background-color: white;
                top:-6px;
                margin-left:-2px;
                border:3px solid #1E222C;
            }
        }
    }

    .rakeback-footer {
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            &.buttons {
                button {
                    background: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%), linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%);
                    border-radius: 1000px;
                    padding:18px 0;
                    border: 1px solid;
                    border-image-source: linear-gradient(215.71deg, #5C86F1 -7.35%, #1F52D6 57.6%, #3464DF 110.3%);
                    font-size:20px;
                    width:120px;
                    transition:100ms;

                    &.available {
                        box-shadow: 0px 24px 48px rgba(31, 82, 214, 0.16);
                        color:#FDFDFF;

                        &:hover {
                            background: linear-gradient(215.71deg, #4871d8 -7.35%, #1648c8 57.6%, #2453ca 110.3%), linear-gradient(258.68deg, #3762cf -25.12%, #1c4ecb 57.73%, #0c2d80 130.84%);
                        }
                    }
                    &.cooldown {
                        color:#FBFBFB7A;
                        cursor: default;
                    }
                    &.cancel {
                        background: #2A2F3D;
                        border:1px solid #2A2F3D;
                        margin-left:10px;
                        color:#FDFDFF;

                        &:hover {
                            background: #333948;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 786px) {
        padding:18px;

        .close-button {
            top:15px;
            right:15px;
        }

        .rakeback-header {
            h1 {
                font-weight: 500;
                font-size:25px;
            }
        }

        .rakeback-footer {
            flex-direction: column;
            padding-top:20px;
            padding-bottom:90px;

            .currencies {
                margin-right:-10px;
            }

            .buttons {
                padding-top:2px;
                width:100%;
                display: flex;
                flex-direction: column;

                button {
                    width:100% !important;
                    margin:15px 0 0 0 !important;
                }
            }
        }
    }
}